.ContentSection {
  padding: 1rem;
  display: grid;
  gap: 1rem;
}
.AppMetricsPage{
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.TrainedModelAppMetricsPage{
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
}

.TrainedModelPromptSection{
  background-color: #fff;
  width: 100%;
  border: 1px solid #cccccc;
  height: fit-content;
  display: flex;
  min-height: 25rem;
  justify-content: flex-start;
  flex-direction: column;
}

.TrainedModelPromptHeader{
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #cccccc;
  gap: 1rem;
  height: 20px;
  justify-content: space-between;
}
.TrainedModelPromptDescription{
  padding: 1rem;
}
.TrainedModelPromptOutPut{
  display: flex;
  background-color: #D9D9D9;
  width: 80%;
  border-radius: 5px;
  flex-direction: column;
  height: 6rem;
  margin: 1rem;
}
.TrainedModelPromptInput{
  display: flex;
  flex-direction: row;
  background-color: #D9D9D9;
  width: 80%;
  margin: 1rem;
  border-radius: 5px;
}

.MetricsCardsSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding-bottom: 1rem;
}

.CardSizeDimensions {
  min-width: 15rem;
  display: flex;
  /* we dont set values the height
       is a pre-requiste for the line
       component, try removing or editing
       it and you will see why it matters.
    */
  height: 18rem;
  color: initial;
}

.SummaryCardDimentions {
  min-width: 100%;
  display: flex;
  border-radius: 15px;
}

.SummarySectionArea {
  padding: 3rem 0 3rem 0;
}

.TopBarSection {
  background-color: black;
}

.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
  max-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* overflow: hidden; */
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow: hidden;
}

.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.SummaryCardContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
}

.CardHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #cccccc;
  gap: 1rem;
  flex-grow: 1;
}

.CardTitle {
  width: 100%;
}

.InnerCard {
  display: grid;
  grid-template-columns: 7fr 1fr 7fr 1fr 7fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  overflow-x: auto;
  width: -webkit-fill-available;
}
.NoteBookInnerCard {
  display: grid;
  grid-template-columns: 7fr 1fr 7fr ;
  grid-template-rows: auto;
  grid-gap: 1rem;
  overflow-x: auto;
  width: -webkit-fill-available;
}

.InnerCardSections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.InnerContentSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InnerContentLeftSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.CardBodySection {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  padding: 2rem;
}

.SummaryCardContainer:hover {
  border: 1px solid var(--secondary-color);
}

.verticalLine {
  border-left: 1px solid #cccccc;
  height: 80%;
  width: 1px;
  ;
  padding: 1rem;
}

.InnerTitlesStart {
  color: #000;
  font-weight: bold;
  text-align: start;
  /* padding: 1rem 1rem 0rem 0rem; */
}

.InnerItemCopy:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.InnerItemCopy .TooltipWordPlaceholder {
  font-weight: normal;
}

.InnerTitlesMiddle {
  color: #000;
  font-weight: bold;
  text-align: center;
}

.InnerTitlesEnd {
  color: #000;
  font-weight: bold;
  text-align: end;
}

.InnerContentGrid {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.InnerContentEnd {
  text-align: end;
}

.InnerContentStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: .5rem;
  color: #555;
}

.InnerContentAge {
  text-align: center;
}

.InnerContentLinkText {
  white-space: nowrap;
  overflow: hidden;
  color: var(--primary-color);
  text-overflow: ellipsis;
}


.InnerContentLinkText a {
  color: inherit;
  text-decoration: none;
}

.InnerContentWarnText {
  overflow: hidden;
  color: #fb0c0c;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: underline;
}

.InnerContentLink {
  display: grid;
  grid-template-columns: auto 10%;
  gap: 0.5rem;
  align-items: center;
}
.InnerContentLink svg{
  height: 1rem;
  width: 1rem;
}

.Icons {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;
}

.CopierDiv {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.MetricCardsSection {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.Redeploy {
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.DeployText {
  color: #fb0c0c;
}

.TrainedModelReadMeSection{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: #fff;
  height: fit-content;
}

.TrainedModelReadMeSectionTabs{
  border-bottom: 1px solid #cccccc;
  width: 90%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 2.5rem;
}
.TrainedModelReadMeSectionSelectedTab{
  background-color: #D9D9D9;
  padding: 0.3rem;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.TrainedModelReadMeSectionUnSelectedTab{
  padding: 0.3rem;
  border-radius: 5px;
  cursor: pointer;
}
.TrainedModelReadMeContainer{
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
}
.TrainedModelUl {
  display: list-item;
  list-style-type: disc;
  margin-left: 20px;
  color: black;
}
.ReadMeTittleText{
  font-size: 1.2rem;
  font-weight: bold;
}


@media only screen and (max-width: 650px) {
  .MetricCardsSection {
    grid-template-columns: repeat(1, 1fr);
  }

  .ContentSection {
    padding-top: 2.5rem;
  }
}

@media only screen and (max-width: 900px) and (min-width: 481px) {
  .MetricCardsSection {
    grid-template-columns: repeat(2, 1fr);
  }
}