.SettingsContainer {
  width: 100%;
  /* Use 100% width to be responsive */
  max-width: 1200px;
  /* Set a max-width that you prefer */
  margin: 0 auto;
}

.LoadingArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*///////////// Delete Model //////////////////*/

.AppDeleteModel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(112, 49, 49, 0.5);
}

.DeployText {
  color: #fb0c0c;
  font-weight: 600;
}

.AppPageLayout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.AppDeleteModel .ModalChildWrap {
  z-index: 3;
}

.DeleteAppModel {
  display: grid;
  gap: 3rem;
}

.InnerContentWarnText {
  overflow: hidden;
  color: #fb0c0c;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: underline;
}

.DeleteModalLowerSection {
  display: grid;
  gap: 1rem;
}

.DeleteDescription {
  cursor: default;
  font-weight: 700;
}

.DeleteDescription span {
  padding: 0.2rem 0.4rem;
  margin: 0;
  font-weight: 700;
  font-size: 90%;
  background-color: rgba(27, 31, 35, 0.1);
  border-radius: 3px;
}

.DeleteAppModelButtons {
  display: flex;
  justify-content: space-between;
}

.DeleteAppModelButtons .DeleteBtn {
  background-color: #ff0000;
  outline: 1px solid #ff0000;
  transform: scaleX(0.9);
}

.DeleteAppModelButtons .DeleteBtn:hover {
  background-color: #fff;
  outline: 1px solid #ff0000;
  color: #ff0000;
}

.InactiveDelete {
  background-color: #fff;
  outline: #ff9595;
  color: #ff9595;
}

.InactiveDelete:hover {
  background-color: #fff;
  outline: #ff9595;
  color: #ff9595;
}

.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
  max-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}

.TopBarSection {
  background-color: black;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
}

.ContentSection {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 3rem;
  flex-direction: column;
  overflow-y: auto;
}

.DeleteWarning {
  color: #f00;
}

.InnerModalDescription {
  display: grid;
  gap: 0.5rem;
}

.DeleteSubDescription {
  font-weight: 500;
}

.WarningContainer {
  display: grid;
  gap: 0.8rem;
  width: 25rem;
}

.APPInstructions {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.APPInstruct {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 0.5rem;
}

.APPButtonRow {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 4.5rem;
}

.APPButtonRow select {
  padding: 0.5rem;
}

/* .SectionButtons {
  display: flex;
  justify-content: flex-end;
  padding-left: 1rem;
} */

.APPSections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 965px) {
  .APPSections {
    padding: 0;
  }
}

.APPSectionPort {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  padding: 1rem 0rem;
  gap: 1rem;
}

.AppDelete {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 2rem 0rem;
}

.APPSectionTitle {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--primary-color);
}

.APPSectionDelete {
  font-size: 0.9rem;
  font-weight: 700;
  color: #ff0000;
}

.AppDeleteInstructions {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.flexa input {
  border: 1px solid;
}

.APPButtonRow .SmallSelectElementValue {
  padding: 0.3rem !important;
}

.AppLabel {
  font-weight: 500;
}

.PrivateImageCheckField,
.CustomDomainCheckField {
  display: grid;
  grid-template-columns: 20% auto;
  gap: 1rem;
}

.PrivateImageTabContainer,
.CustomDomainTabContainer {
  border-left: 5px solid #e1e2e2;
  padding: 0rem 2rem;
}

.PrivateImageInputs,
.CustomDomainInputs {
  display: grid;
  row-gap: 1rem;
}

.ShowStatus {
  display: grid;
  grid-template-columns: 30% auto;
  justify-content: flex-start;
}

.StatusIcon {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.3rem;
}

.CopyUrl {
  /* color: var(--primary-color); */
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.CopyUrl:hover {
  color: var(--primary-color);
  transform: scale(1.1);
}

.CopyDiv {
  display: flex;
  gap: 3rem;
  justify-content: flex-start;
}

.UpperSection {
  display: grid;
  justify-content: end;
}

.HorizontalLine {
  border: 0;
  width: 100%;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.HorizontalHalfLine {
  border: 0;
  width: 15%;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.DeleteBtn {
  background-color: #ff0000;
  outline: 1px solid #ff0000;
}

.DeleteBtn:hover {
  background-color: #fff;
  outline: 1px solid #ff0000;
  color: #ff0000;
}

.Environments .PortSection {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  gap: 1rem;
  padding-bottom: 1rem;
}

.varInput {
  width: auto;
  background: transparent;
  border: 1px solid #000;
  padding: 0.5rem;
}

.AppOtherSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.APPOptionsButton {
  display: flex;
  justify-content: flex-end;
}

.portInput {
  width: auto;
  background: transparent;
  border: 1px solid #000;
  padding: 0.5rem;
  padding-left: 1rem;
  width: 94%;
}

.commandInputSection {
  display: grid;
  padding-left: 3.8rem;
}

.EnvVarsInputGroup {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.5rem;
}

.EnvVarsInputGroup .EnvVarsInputs {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.EnvVarsAddBtn>* {
  height: 100%;
}

.ModalFormInputsEnvVars {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.RemoveIconBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RemoveIconBtn:hover {
  background-color: #fb0c0c;
  color: #fff;
  border: 1px solid #fb0c0c;
}

.RemoveIconUpdating {
  background-color: #fb0c0c;
  opacity: 0.2;
  color: #fff;
  border: 1px solid #fb0c0c;
}

/* .EnvVarsInputGroup input {
  max-width: 10rem;
}

.EnvVarsInputGroup input::placeholder {
  color: grey;
} */

.EnvData {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
}

.EnvInputItem {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-input.name-input {
  flex: 0 0 30%;
}

.settings-input.value-input {
  flex: 0 0 68%;
}

.VarInputGroup {
  display: flex;
  gap: 1rem;
  width: 78%;
}

.EnvDataItem {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  align-items: center;
}

.EnvDataItem div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EnvValue {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.EnvValue>div {
  border: 1px solid #777;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
}

/* .VarsRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.3rem;
}

.varsTable {
  width: 100%;
}
.TableRow {
  display: flex;
  justify-content: flex-end;
} */

.deactivatedBtn {
  background-color: transparent;
  color: var(--secondary-color);
}

.Domain__Popup {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.Rollback__Option,
.Domain__Popup:hover {
  color: var(--secondary-color);
}

.DomainModal__Main {
  display: grid;
  grid-template-rows: 13% auto;
}

.DomainModal__Title {
  font-size: 25px;
  font-weight: 700;
  line-height: 42.1px;
}

.Description__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.Description__button button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  width: 9rem;
  height: 2.9rem;
  font-weight: bold;
}

.Step__Title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.Step__Description {
  padding: 0rem 0rem 1rem 1rem;
}

ul li {
  list-style-type: none;
}

.RevertSection {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 1rem;
}

.NoResourcesMessage {
  flex-grow: 1;
  display: flex;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.AppRevision {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.APPInstruct:last-child {
  border-bottom: none;
}

.RevisionStatus {
  display: flex;
  justify-content: space-between;
}

.Success {
  color: green;
  height: 30px;
}

.RevisionNumber {
  color: #008ac1;
  height: 30px;
}

.RevisionSummary {
  display: flex;
  /* This will align your items in a row */
  justify-content: space-between;
  /* This will put maximum space between items */
  align-items: center;
  /* This will vertically center align items */
}

.Time {
  margin-right: auto;
  /* This will push all the elements after Time to the right */
}

.AppDetails {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
}

.Danger {
  color: red;
  width: 40px;
  height: 40px;
}

.SeparatorDot {
  width: 30px;
  height: 20px;
}

.LeftItems {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 1rem;
}

.RightItems {
  display: grid;
  grid-template-columns: 1fr auto;
}

.Entity {
  font-size: 1.1rem;
  margin-right: 10px;
}

.Time {
  padding-left: 1rem;
  color: gray;
  margin-right: 10px;
}

.Rollback__Option {
  color: #008ac1;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
}

.TopSection {
  display: flex;
  justify-content: space-between;
}

.ReviseAppModal {
  display: grid;
  gap: 3rem;
}

.ReviseAppModalLowerSection {
  display: grid;
  gap: 1rem;
}

.ReviseAppDescription {
  cursor: default;
  font-weight: 700;
  font-size: 1.1rem;
  text-align: center;
}

.ReviseAppModalButtons {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 650px) {
  .APPSections {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    min-width: 25rem;
  }

  .EnvDataItem {
    display: flex;
    flex-wrap: wrap;
  }

  .ContentSection {
    padding-top: 2.5rem;
  }

  .varInput {
    width: 10rem;
  }

  .EnvInputItem {
    display: flex;
    flex-wrap: wrap;
  }
}


.SettingsSectionInfo2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.3rem;
  padding-left: 0.5rem;
  font-size: 0.95rem;
  justify-content: space-between;
}

.ApplicationTabs {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.MemberTableRow {
  display: flex;
  justify-content: space-between;
  /* To space the children evenly */
  align-items: center;
  /* Vertically center-align the content */
  border-bottom: 1px solid #e0e0e0;
  /* Add a bottom border to separate rows */
  padding: 10px 0;
  /* Add some padding for spacing */
}

.MemberTableRow:last-child {
  border-bottom: none;
}


.ImageSection .APPButtonRow {
  gap: 2.5rem;
  padding-bottom: 1rem;
}

.version-item {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.version-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.version-details {
  display: flex;
  align-items: center;
}

.upload-icon {
  margin-right: 10px;
}

.upload-icon svg {
  height: 1.5rem;
  width: 1.5rem;
}

.version-header {
  display: flex;
  flex-direction: column;
}

.version-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;
}

.version-name:hover {
  white-space: normal;
  word-wrap: break-word;
  overflow: visible;
}

.version-id {
  color: #008ac1;
}

.version-date {
  justify-self: start;
}

.rollback-link {
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}

.last-column-container {
  display: flex;
  justify-content: flex-end;
}

.current-label {
  /* Style for the 'Current' label */
  background-color: #C8E6C9;
  /* Example color */
  padding: 2px 8px;
  border-radius: 4px;
  color: green;
  font-weight: bold;
}

.error-label {
  background-color: #ff9595;
  padding: 2px 8px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.pagination a {
  color: #0056b3;
  text-decoration: none;
}

.pagination a:hover {
  text-decoration: underline;
}

.settings-input {
  width: 70%;
  padding: 0.6rem;
}

.ImageSection .SelectWrapper {
  width: 70%;
}


.PrivateImageInputs .APPButtonRow {
  gap: 6rem;
}

.PrivateImageInputs .APPButtonRowEmail {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-flow: column;
  align-items: center;
  padding-bottom: 1rem;
  gap: 6.5rem;
}

.PrivateImageInputs .APPButtonRowRegistry {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-flow: column;
  align-items: center;
  padding-bottom: 1rem;
  gap: 6.5rem;
}

.PrivateImageInputs .APPButtonRowName {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-flow: column;
  align-items: center;
  padding-bottom: 1rem;
  gap: 5.8rem;
}

.PrivateImageInputs .sub-input {
  width: 190%;
  padding: 0.6rem;
}

.PrivateImageInputs .sub-input-email {
  width: 190%;
  padding: 0.6rem;
}

.cardSectionTitle {
  font-size: 0.9rem;
  font-weight: bold;
}

.DBTDetail {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.CustomDomainInputs .domain-input {
  width: 79%;
}

.CustomDomainInputs .APPButtonRow {
  gap: 2.8rem;
}

.settings-input[readonly] {
  cursor: pointer;
}

.SectionSplit {
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.SuccessMessageArea .SuccessOnWhite {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  width: 40%;
}