.Brand {
  width: 120px;
  height: 120px;
  fill: var(--primary-color);
}

.SectionsContainer {
  flex: 1;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(rgb(255, 255, 255) 0.5px, transparent 0.5px),
    radial-gradient(rgb(255, 255, 255) 0.5px, var(--primary-color) 1px);
  background-size: 24px 24px;
  background-position: 0 0, 10px 10px;
}

.LoginContent {
  display: flex;
  flex-direction: column;
}

.BrandText {
  font-size: 3rem;
  color: var(--primary-color);
}

.BrandSubText {
  font-size: 1.3rem;
  text-align: center;
  color: var(--gray-color);
}

.LeftSectionContent,
.RightSectionContent {
  padding: 20px;
}

.LeftSectionContent {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.LeftSectionContent h1 {
  margin: 0
}

.LoginContentHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RightSectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  background: #fff;
  color: var(--primary-color);
  padding:  2rem;
  border-radius: 1rem;
}


.LoginContentTitle {
  margin: 0;
  font-size: 1.5rem;
}

.AuthBtn {
  padding: 0.7rem;
  height: auto;
}

.LoginContentInputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
}

.RightSectionContent input {
  color: #000;
  border: 1px solid #000;
}

.RightSectionContent .InputText::placeholder {
  color: #888888; /* Change this to your desired color */
  opacity: 1; /* Firefox requires this to show the custom color */
}

/* .LoginContentInputs>* {
  width: 100%;
} */

.LowerLoginSection {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  gap: 0.5rem;
}

.LoginLinkContainer {
  display: inline-block;
}

.LoginContentLink {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.LoginErrorDiv {
  background-color: rgba(255, 255, 255, 0.597);
  color: #d63b3b;
  text-align: center;
  padding: 1rem 0;
  border-radius: 5px;
}

.LoginFeedBackDiv {
  background-color: rgba(255, 255, 255, 0.5);
  color: #026d0a;
  text-align: center;
  padding: 1rem 0;
}

.LoginContentBottomLink {
  padding-top: 1rem;
}

.LoginButton {
  width: 100%;
}

.LoginButton:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.GitLoginBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.LogoIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.GithubLoginBtn {
  width: 100%;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}

.GithubLoginBtn:hover {
  border: 1px solid var(--primary-dark-color);
  background-color: var(--primary-dark-color);
}

.GitText {
  color: #fff;
  text-transform: capitalize;
}

.LoginWith {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #bcbcbc;
  line-height: 0.1em;
}

.LoginWith span {
  background-color: #fff;
  padding: 0 1rem;
}

@media (max-width: 460px) {
  .LoginContent {
    padding: 0 2rem;
  }

  .LoginContentInputs {
    width: 100%;
  }
}

.password-wrappers {
  display: grid;
  align-items: center;
  border-radius: 5px;
  position: relative;
}


/* .CopyIcon {
  background-color: #abead5;
  height: 100%;
  padding: 0 0.4rem;
  width: 1.3rem;
} */

.password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .LeftSectionContent {
    display: none;
  }

  .RightSectionContent {
    padding: 0 2rem;
    width: 100%!important;
    border-radius: 0;
  }
}