.card {
    position: relative;
    background-color: #ffff;
    padding: 20px;
    border-radius: 8px;
    margin: 10px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tagName {
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 8px;
    text-transform: capitalize;
}
.tagName:hover{
    cursor: pointer;
}
.statItem {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    display: flex;
    width: fit-content;
    gap: 0.5rem;
    align-items: center;
}

.statItem p,
.statItem span {
    margin: 0;
    font-size: 0.9em;
    color: black;
}

.stats {
    width: 10;
    height: 10;
}

.tagInfo {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;
}

.modalFollowButton {
    padding-bottom: 0.5rem;
}

.followButtonArea {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.followButton {
    background-color: var(--secondary-color);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
}