.ProjectList {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 2rem 2rem 4rem 2rem;
  }
  
  .ProjectsDropDown {
    display: flex;
    flex-direction: column;
  }
  
  .SelectProjects {
    padding-top: 1.5rem;
  }
  
  .TopItem {
    display: flex;
    width: 13rem;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
    font-size: 17px;
    background-color: #d9d9d9;
    padding: 1rem;
    border-radius: 0.4rem;
  }
  
  .closeDrop {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  
  .itemsList {
    width: 12rem;
    gap: 1rem;
    padding-top: .5rem;
    position: relative;
    animation-name: example;
    left: 2rem;
    animation-duration: 1s;
  }
  
  @keyframes example {
    0% {
      left: 0px;
    }
  
    100% {
      left: 2rem;
    }
  }
  
  .ProjectCategoryHeadings {
    padding: 0.3rem;
    font-size: large;
    display: flex;
    gap: 1.3rem;
  }
  
  .ProjectCategories {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  
  .CurrentTab {
    background-color: #d9d9d9;
    padding: 0.5rem;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .CurrentTab:hover,
  .Tab:hover {
    cursor: pointer;
  }
  
  .Tab {
    padding: 0.5rem;
  }
  
  .SmallerIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .ProjectFormErrorDiv {
    background-color: rgba(255, 255, 255, 0.5);
    color: #d63b3b;
    text-align: center;
    padding: 1rem 0;
    height: 1rem;
  }
  
  .ProjectDeleteModel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .Role {
    font-weight: 600;
  }
  
  .ModelContent {
    width: 23rem;
  }
  
  .ProjectDeleteModel .ModalParentWrap {
    background-color: inherit;
  }
  
  .UpdateProjectModelButtons {
    display: flex;
    justify-content: space-between;
  }
  
  .UpdateForm {
    display: grid;
    gap: 1rem;
    padding: 1rem 1.3rem;
    grid-template-rows: 2fr;
  }
  
  .InformationText {
    width: 20rem;
  }
  
  .InformationWarning {
    color: #d63b3b;
  }
  
  .ModelHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 2rem;
  }
  
  .BlueBtn:hover {
    color: var(--primary-color);
    background-color: #fff;
    outline: 2px solid var(--primary-color);
  }
  
  .BlueBtn {
    color: #fff;
    background-color: var(--primary-color);
    outline: 2px solid var(--primary-color);
    padding: 0.5rem;
    height: 2.5rem;
  }
  
  .ProjectDeleteModel .ModalChildWrap {
    z-index: 3;
  }
  
  .ProjectDeleteModel .ModalChildWrap {
    min-width: 21rem;
    max-width: 25rem;
  }
  
  .ClusterDrop {
    width: 25rem;
    border: 2px solid #000000;
    box-sizing: border-box;
    font-size: inherit;
    padding: 1rem;
  }
  
  .ClusterNameOption {
    padding: 2rem 1.5rem;
    color: #000;
    background-color: #f9f9f9;
    font-weight: bold;
  }
  
  .MainRow>.ConnectModal {
    width: 100%;
    margin-left: 0;
  }
  
  .MainRow {
    background-color: var(--gray-bg-light-color);
    min-height: calc(100vh - 14rem);
    position: relative;
  }
  
  .NoResourcesMessage {
    flex-grow: 1;
    display: flex;
    font-size: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 12rem;
  }
  
  .ButtonPlusSmall {
    width: 1rem;
    height: 1rem;
  }
  
  .FooterRow {
    padding: 1rem 3rem;
    background-color: var(--gray-bg-light-color);
  }
  
  
  
  .PaginationSection{
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  
  @media (max-width: 500px) {
    .ProjectList>div {
      width: 100% !important;
    }
    .NoResourcesMessage{
    font-size: 12px;
    padding: 3rem;
    }
  }
  
  /* @media only screen and (max-width: 1100px) and (min-width: 901px) {
    .ProjectList {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media only screen and (max-width: 900px) and (min-width: 481px) {
    .ProjectList {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  } */