.SmallContainer {
  display: grid;
  padding-top: 1rem;
  padding-left: 5.8rem;
  padding-right: 3rem;
  gap: 1rem;
  background: var(--gray-bg-light-color);
}

.Page {
  display: flex;
  flex-flow: column;
  height: 100vh;
  flex-direction: column;
  background-color: var(--gray-bg-light-color);
}

.Header {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: row;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 15px;
  border: 1px solid var(--gray-light-color);
}

.FilterItemsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Heading {
  font-weight: bolder;
  line-height: 1.8;
  font-size: larger;
}

.ActivityHeader {
  display: flex;
  gap: 1rem;
}

.ActivityStatus {
  display: flex;
  gap: 3.5rem;
  padding-left: 3.5rem;
  text-align: left;
}

.ActivityDescription div{
  /* padding-right: 1rem; */
  max-width: 15rem; 
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ActivityDescription div:hover{
  overflow: visible;
  white-space: normal;
  word-wrap: break-word;
}

.FilterItemLabel {
  text-align: center;
}

.DateBtn {
  padding: 0.5rem;
  font-size: 0.9rem;
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.ActivityPageTitle {
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
}

.SimpleForm {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
}

.SmallContainer .Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FilterItemDropdown {
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1 1 1;
}

.FilterItemLabel {
  margin-right: 10px;
}

.TableRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.Success {
  color: green;
  /* padding-left: 1rem; */
}

.Danger {
  color: red;
  /* padding-left: 1rem; */
}

.Entity {
  color: var(--primary-color);
  overflow: hidden;
  max-width: 17rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Entity:hover{
  overflow: visible;
  white-space: normal;
  word-wrap: break-word;
}
.hr {
  margin: .5rem 0;
}

.Row {
  display: flex;
  justify-content: space-between;
  gap: 3.5rem;
  /* width: -webkit-fill-available; */
}

.RowCell {
  display: flex;
  gap: 2rem;
}

.LastCell {
  align-self: flex-end;
}

.UserAvatar {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ErrorSection {
  width: 40%;
}

@media(max-width: 650px) {
  .Header {
    flex-direction: column;
  }

  /* .LogsContainer {
    margin-top: 5rem;
  } */

  .ActivityPageTitle {
    font-size: 15px;
    padding-top: 0.1rem;
  }

  .BackIconSize {
    width: 1.5rem;
    height: 1.5rem;
  }

  .DateSection {
    padding: 2px 0px 2px 0px;
    gap: .2rem;
  }

  .OuterFilterItem {
    width: auto;
  }

  .Page {
    min-width: 50rem;
    height: 200vh;
  }

}