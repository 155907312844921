header.NewHeader {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  /* height: 5rem; */
  padding: 0.6rem 3rem;
}

.DownArrowWrapper:hover {
  cursor: pointer;
}

.UpArrowWrapper:hover {
  cursor: pointer;
}

.HeaderLink {
  display: grid;
  grid-auto-flow: column;
}

.HeaderLink a {
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
}

.HeaderLink a:hover {
  transition: ease-in-out 0.2s;
  background-color: var(--secondary-color);
}

.HeaderLink .active {
  background-color: var(--secondary-color);
}

.LogoLink:focus {
  outline: none;
}

.LogoLink::-moz-focus-inner {
  border: 0;
}

.OnHead {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}

.OnHead:hover .UserNames {
  background-color: var(--secondary-color);
  color: #000000;
}

.OnHead:hover svg {
  fill: #000;
}

div.DropDownGrid {
  display: flex;
  flex-direction: column;
}

.HeaderLinksWrap {
  display: flex;
}

div.HeaderLinksWrap.LoggedIn .BelowHeader {
  background-color: #f9f9f9;
  position: absolute;
  top: 100%;
  width: 10rem;
  z-index: 20px;
  right: 0;
}

.DropDownArrowSvg {
  width: 2rem;
  height: 2rem;
  fill: #000000;
}

.UserName {
  font-weight: bold;
  color: #000;
  cursor: default;
  text-transform: capitalize;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.DropDownContent {
  color: #000;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  z-index: 10px;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.DropDownLink {
  cursor: pointer;
  padding: 1rem 1.5rem;
}

.DropDownLink:hover,
.DropDownLink:active {
  background-color: var(--gray-bg-light-color);
  cursor: pointer;
}

.DropDownLink,
.DropDownLink:visited {
  color: #000;
  text-decoration: none;
}

.HeaderLinkBack:hover {
  background-color: var(--secondary-color);
}

a.HeaderLinkDocs {
  color: #000;
  border-radius: 10px;
}

a.HeaderLinkDocs:hover {
  background-color: var(--secondary-color);
}

.Headerarrow {
  display: flex;
  height: 4rem;
  width: 4rem;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

}

.HeaderDropdown {
  position: absolute;
  top: 2rem;
  right: 0.5rem;
}

.HeaderDropdown .HeaderDropLinkOption {
  padding: 0 2rem 0 2rem;
  line-height: 2.5rem;
  height: 2.5rem;
  color: #000000
}

.HeaderDropdown .HeaderDropLinkOption:hover {
  background-color: var(--primary-color);
  cursor: pointer;
  color: #ffffff;
}

.HeaderDropItems {
  display: flex;
  border: #000;
  flex-direction: column;
}

.HeaderDropLinks {
  display: flex;
  background-color: #fff;
  width: 7rem;
  flex-direction: column;
  border-radius: 0.3rem;
  border: 1px solid #8e8e8e;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.HeaderDropLinks .HeaderDropLinkOption {
  position: relative;
  padding: 0 1rem 0 1rem;
}

.HeaderDropLinks .HeaderDropLinkOption::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.HeaderDropLinks .HeaderDropLinkOption:last-child::after {
  display: none;
}

.HeaderDropLinkDocs {
  text-align: end;
  padding-right: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: #000;
  border-bottom: #000 solid 1px;

}

.HeaderDropLinkDocs:hover {
  background-color: var(--secondary-color);
}

@media only screen and (max-width: 640px) {

  .HeaderLinkPricing,
  .HeaderLinkDocs {
    display: none;
  }
  header.NewHeader {
    padding: 0.4rem 0.9rem;
  }
}
