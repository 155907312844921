.LogsFrameContainer {
  border: 1px solid #cccccc;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px; /* *** */
  background-color: #ffffff;
  color: initial;
  height: 100%;
  border-radius: 15px;
}
.LogsFrameContainer:hover{
  border: 1px solid var(--secondary-color);
}

.LogsHeaderSection {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
}

.LogsTitle {
  font-weight: bold;
}

.LogsBodySection {
  padding: 0.5rem 1rem;
  font-family: monaco;
  font-size: 13px;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.LogsSpinner {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.LogsAvailable span {
  white-space: pre-wrap;
}

.LogsEmpty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Dark mode */

.LogsFrameContainer.Dark {
  background-color: #283237;
}

.LogsHeaderSection.Dark {
  color: #ffffff;
  border-bottom: 1px solid #000000;
}

.LogsBodySection.Dark {
  color: var(--primary-light-color);
}
