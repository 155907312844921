.NewPasswordPageContainer {
  flex-grow: 1;
  background: var(--primary-color) !important;
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.NewPasswordContent {
  flex-grow: 1;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.NewPasswordContentInputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.NewPasswordMessage {
  align-self: center;
  justify-self: center;
}

.NewPasswordSuccessContent {
  min-height: 25rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
.NewPasswordSuccessContent h2 {
  color: var(--secondary-color);
}

.NewPasswordSuccessContent p span {
  color: var(--secondary-color);
}

.NewPasswordErrorDiv {
  background-color: rgba(255, 255, 255, 0.5);
  color: #d63b3b;
  text-align: center;
  padding: 1rem 0;
}

@media only screen and (max-width: 640px) {
  .NewPasswordContent {
    width: 90%;
  }

  /* .InputText {
    width: 90%;
  } */
}
.password-wrap {
  position: relative;
  display: grid;
  align-items: center;
}
.password-wrap .toggle-password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.password-input-container {
  position: relative;
}
.password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.password-repeat {
  position: relative;
  display: grid;
  align-items: center;
}

.passwordConfirm.toggle-password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.passwordConfirm {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
