.ClusterCard {
  background-color: #ffffff;
  border: 1px solid #e0dddd;
  width: 15rem;
  height: min-content;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: box-shadow .3s;
}

.ClusterCard:hover {
  transition: 0.5s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(0.95);
}

.CardImageDiv {
  margin: 0;
  width: 100%;
  height: 150px;
  background-color: var(--primary-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ClusterBottomContainer {
  padding: 0.5rem;
  height: 4rem;
  flex-direction: row;
  background-color: #f6fbe5;
  justify-content: space-between;
  border-radius: 0 0 .4rem .4rem;
}

.ClusterCardName {
  color: #000;
  flex-grow: 1;
  font-size: 1.1rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 12rem
}

.ClusterCardDesc {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #3c3c3c;
  display: -webkit-box;
  font-size: .9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}