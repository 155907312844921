.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2rem;
  height: calc(100vh - 14rem);
}

.DatabaseTableHead {
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
  grid-row: 1;
  padding: 0.5rem 0;
}

.DatabaseTableRow {
  display: grid;
  color: #000000;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  border-bottom: solid 1px #cccccc;
}

.DatabaseTableRow:not(a) {
  border-bottom-width: 2px;
}

.CardBodySection .DatabaseTableRow {
  width: 100%;
}

.DatabaseBody .DatabaseTableRow:hover {
  /* color: var(--secondary-color); */
  background-color: var(--gray-light-color);
}

.DatabaseTableCell {
  font-weight: normal;
  padding: 1rem 0;
  text-align: center;
  grid-row: 1;
  overflow: hidden;
  overflow-wrap: break-word;
}

.DatabaseTableCell div {
  justify-content: center !important;
}

.DatabaseTableHead:first-child,
.DatabaseTableCell:first-child {
  text-align: left;
  padding-left: 1rem !important;
}

.DatabaseTableCell:first-child {
  text-transform: uppercase;
}

.DatabaseTableHead:last-child,
.DatabaseTableCell:last-child {
  text-align: right;
  padding-right: 1rem !important;
}

.NoResourcesMessageSection {
  flex-grow: 1;
  display: flex;
  height: 50vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 6rem;
}

.NoResourcesMessage {
  font-size: 16px;
  text-align: center;
  justify-content: center;
}

.ButtonPlusSmall {
  display: inline;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.DatabaseTable:hover {
  border-color: #cccccc;
}

.MainPage {
  /* Divided into two rows */
  margin: 0;
  background: var(--gray-bg-light-color);
  height: 100vh;
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow-x: hidden;
}

.MainSection {
  /* divided into two columns */
  background-color: var(--gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.MainContentSection {
  width: 100%;
}

@media(max-width: 650px) {
  .MainSection {
    /*one column required */
    display: flex;
    overflow: auto;
  }

  .ContentSection {
    padding-top: 3.5rem;
  }

  .NoResourcesMessageSection {
    padding: .2rem;
  }


}

td .DatabaseLink {
  text-decoration: none;
  color: #000000;
}