.BackDiv img {
  display: inline;
}

.BackDiv {
  display: inline;
  color: black;
}

.BackDiv p {
  display: inline;
}
.BackDiv img:hover {
  transition: 0.2s;
  transform: scale(1.2);
}

/*! ////////////////////////////////////////// */
.ModalForm.AddAppModal .ModalFormHeading {
  margin-bottom: 0;
}

.ModalFormInputsBasic {
  display: grid;
  gap: 1rem;
}

.ModalFormInputsBasic input {
  max-width: 25rem;
}

.ModalFormInputsBasic input::placeholder {
  color: gray;
}

.EnvVarsInputGroup {
  width: 100%;
  max-width: 25rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.5rem;
}

.EnvVarsInputGroup .EnvVarsInputs {
  width: 70%;
  max-width: 25rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.EnvVarsInputGroup .EnvVarsAddBtn {
  position: relative;
  display: flex;
  align-items: center;
}

.EnvVarsInputGroup .EnvVarsAddBtn button.EnvVarAddBtn {
  position: absolute;
  max-width: 100%;
  color: #fff;
  background-color: var(--primary-color);
  outline: 2px solid var(--primary-color);
}

.EnvVarsInputGroup .EnvVarsAddBtn button.EnvVarAddBtn:hover {
  color: var(--primary-color);
  background-color: #fff;
}

.EnvVarsInputGroup input {
  max-width: 10rem;
}

.EnvVarsInputGroup input::placeholder {
  color: grey;
}

.ModalFormButtons {
  width: 100%;
}

.EnvVarsTable {
  width: 100%;
  margin-bottom: 1rem;
}

.EnvVarsTable table {
  max-width: 25rem;
  text-align: left;
}

.EnvVarsTable table thead td {
  font-weight: normal;
  color: #555;
}

.EnvVarsTable table thead td:last-child {
  width: 4rem;
  text-align: right;
}

.EnvVarsTable table td:last-child {
  width: 4rem;
  display: block;
  margin: auto;
}

.EnvVarsTable table td {
  font-weight: normal;
}

.EnvVarsTable table td img {
  margin: auto;
}

.EnvVarsTable table td img:hover {
  /* cursor: pointer; */
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(180deg)
    brightness(119%) contrast(5%);
}

.EnvVarsTable thead,
.EnvVarsTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/*- //////////////////////////////////////////////////// */

.ModalForm.AddAppModal {
  display: grid;
  gap: 2rem;
  height: 100%;
}

.ModalForm.AddAppModal input {
  height: 3.1rem;
}

.ModalForm.AddAppModal .ModalFormHeading {
  flex: 0 1 auto;
}

.ModalForm.AddAppModal .ModalFormInputs {
  padding-right: 10px;
  flex: 1 0 auto;
  overflow-y: auto;
}

.ModalForm.AddAppModal .ModalFormButtons.AddAddButtons {
  flex: 0 1 40px;
}

.ModalForm.AddAppModal .ModalFormInputs::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
.ModalForm.AddAppModal .ModalFormInputs::-webkit-scrollbar-track {
  background: var( --gray-bg-light-color);
}
.ModalForm.AddAppModal .ModalFormInputs::-webkit-scrollbar-thumb {
  background: #555;
}
.ModalForm.AddAppModal .ModalFormInputs::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.HeadingWithTooltip {
  display: flex;
}

.DbSupportSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0%;
  padding-bottom: 1rem;
}

.HeadingWithTooltip h4 {
  margin: 0;
  padding: 0.5rem 0;
}

.DbSupportCheckField,
.PrivateImageCheckField {
  display: flex;
}

.InputFieldWithTooltip {
  max-width: 25rem; /* same as input field*/
  display: flex;
  position: relative;
}

.InputTooltipContainer {
  position: absolute;
  right: 0;
  padding-right: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.DatabaseSupportTabContainer,
.PrivateImageTabContainer {
  max-width: 25rem; /* this is to correspond with the width of the input elements */
}

.PrivateImageInputs,
.DatabaseSupportInputs {
  display: grid;
  gap: 0.5rem;
}

.PrivateImageInputs input,
.DatabaseSupportInputs input {
  width: auto;
}

.DbCheckBoxInputGroup {
  display: grid;
  gap: 1rem;
}

.ConnectModal {
  width: 82.19%;
  margin-left: 17.81%;
}


.AppsPage {
  padding: 2rem;
}