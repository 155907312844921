
.Header {
  background-color: var(--primary-color);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  position: relative;
}
.HeaderLinks {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
}
.HeaderLinks a {
  font-size: 0.9em;
  color: #fff;
}
.HeaderLinkLogin {
  border: 1px solid #fff;
  padding: 1rem 3rem;
  font-size: 0.9rem;
  color: #fff;
  border-radius: 5px;
}

@media(max-width: 460px) {
  .HeaderLinkLogin {
    padding: 0.5rem 1rem;
  }
}
  
.HeaderLinkLogin:hover {
  background-color: #fff;
  color: var(--primary-color);
}
.TurnLight:hover {
  transition: 0.4s;
  background-color: var( --gray-bg-light-color);
  color: #000 !important;
}
/* remove focus from logo link - mozilla */
.LogoLink:focus {
  outline: none;
}
.LogoLink::-moz-focus-inner {
  border: 0;
}

.HeaderLinksWrap {
  display: flex;
}

.OnHeader {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.DropdownArrowSvg {
  width: 2rem;
  height: 2rem;
  fill: white;
  transition: all 400ms; 
}
.rotate180 {
  transform: rotate(180deg);
}
.OnHeader:hover .UserNames {
  color: var(--secondary-color);
}
.OnHeader:hover .DropdownArrowSvg {
  fill: var(--secondary-color);
}
.BelowHeader {
  /* background-color: #f9f9f9; */
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 13rem;
  z-index: 30;
  right: 0;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  border: 1px solid #dedede;
  border-radius: 0 0 5px 5px;
}

.UserNames {
  font-weight: bold;
  color: #fff;
  cursor: default;
  text-transform: capitalize;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.Credits  {
  color: var(--secondary-color);
  padding-right: 1rem;
}
.DropDownContent {
  color: #000;
  background-color: #dedede;
  display: flex;
  gap: 1px;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}


.RoundBottom{
  border-radius: 0 0 5px 5px;
}
.DropDownLink {
  cursor: pointer;
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff;
  font-size: 0.9rem;
}

.DropDownLink svg{
  width: 1rem;
  height: 1rem;
}
.DropDownLink:hover,
.DropDownLink:active {
  background-color: var( --gray-bg-light-color);
  background-color: var( --primary-light-color);
}
.DropDownLink,
.DropDownLink:visited {
  color: #000;
  text-decoration: none;
}
.UserInformation{
  display: flex;
  align-items: flex-start;
  padding: 0.7rem 1rem;
  gap: 0.5rem;
  background-color: #e2f5ff;
}

.UserInformation:hover,
.UserInformation:active {
  background-color: var( --gray-bg-light-color);
}
.UserInformation,
.UserInformation:visited {
  color: #000;
  text-decoration: none;
}

.UserDetails{
  font-size: .8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  inline-size:90%;
  justify-content: center;
  color: rgb(56, 56, 56);
}

.HeaderUserName{
  font-weight: 600;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color:#000;
  text-transform: capitalize;
}

.UserAvatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: .9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.HeaderLinkBackToConsole {
  text-transform: uppercase;
  border: 2px solid #ffffff;
  padding: 1rem 3rem; /* margin-left: 2rem; */
  font-size: 0.9em;
  color: white;
  padding: 1rem;
}
.StripBorder {
  text-transform: uppercase;
  padding: 1rem 3rem;
  font-size: 0.9em;
  color: white;
  padding: 1rem;
}
.Credits{
  display: grid;
  grid-auto-flow: column;
  width: 3rem;
  align-items: center;
}
@media only screen and (max-width: 640px) {
  .HeaderLinkPricing,
  .HeaderLinkDocs {
    display: none;
  }
  .UserNames{
    font-size: 14px;
    display: none;
  }
  .OnHeader {
    gap: .3rem;
  }
  .Credits{
    display: none;
  }
  
}
