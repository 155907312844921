.TabsContainer {
  border: 2px solid #000;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.TabHeadingList {
  margin-top: 0;
  background-color: var( --gray-bg-light-color);
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  height: 3rem;
}

.TabContent {
  /* padding: 0 1rem; */
  padding: 1rem 1rem 0;
}

.TabHeadingListItem {
  cursor: pointer;
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 2rem;
}

.TabHeadingListItemActive {
  background-color: white;
  border-width: 0;
  border: none;
}
