.featuredProjects {
    background-color: #f8f9fa;
    padding: 0 1rem 1rem 1rem;
    border-radius: 8px;
    margin: 10px;
}

.noActivity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

.viewMoreButton {
    width: 100%;
}