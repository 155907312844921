.featuredUsers {
    background-color: #f8f9fa;
    padding: 0.2rem 1rem 1rem 1rem;
    border-radius: 8px;
    margin: 10px;
}

.viewMoreButton {
    width: 100%;
}

.noActivity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    text-align: center;
}

.titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.followButton {
    background-color: var(--secondary-color);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
}