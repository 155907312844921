.TextArea {
  width: 25rem;
  border: 1px solid black;
  box-sizing: border-box;
  font-size: inherit;
  padding: 1rem;
}
.TextArea::placeholder {
  color: #808080;
}

.WhiteBackground {
  color: #000;
}
.InitialBackground {
  color: #fff;
  background-color: transparent;
}

/* make it unresizable */
textarea {
  resize: none;
}
