@font-face {
  font-family: proxima-nova;
  src: url("./assets/fonts/proxima-nova-regular.otf") format("truetype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 100;
  src: url("./assets/fonts/proxima-nova-thin.otf") format("truetype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 600;
  src: url("./assets/fonts/proxima-nova-bold.otf") format("truetype");
}

@font-face {
  font-family: monaco;
  src: url("./assets/fonts/monaco.ttf") format("truetype");
}

html {
  --primary-color: #008ac1;
  --secondary-color: #f7b21f;
  --secondary-light-color: #ffcc5f;
  --gray-color: #555;
  --gray-light-color: #ccc;
  --primary-dark-color: #06222f;
  --primary-light-color: #b6e3f9;
  --gray-bg-light-color: #e5ebf3;
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.8rem;
}

#root>div {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  flex-direction: column;
  /* justify-content: space-between; */
  background-color: var(--gray-bg-light-color);
}

textarea,
input,
select,
button {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
}

textarea:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
}

.FullWidth {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.tiny {
  font-weight: 200;
}

.bold {
  font-weight: 500;
}

.extra-bold {
  font-weight: 900;
}

a {
  text-decoration: none;
}

textarea,
input,
select,
button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 5px;
  margin: 0;
}

textarea:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
}

img {
  display: block;
}

input[type="text"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="password"] {
  box-sizing: border-box;
}

.Terms {
  padding: 2rem;
  line-height: 18px;
}

/*- ///// GENERAL DASHBOARD STYLING ///////// */
.DashboardContainer {
  height: auto;
  display: flex;
  flex-direction: column;
}

.DashboardMainContainer {
  margin-top: 80px;
  /* Height of the hedaer */
  display: flex;
  flex-direction: row;
}

.DashboardSideNav {
  min-width: 250px;
}

.DashboardContentWrap {
  flex: 1;
  background: var(--gray-bg-light-color);
}

.FailedToRetrieveMsg {
  text-align: center;
  color: #555;
  display: flex;
  justify-content: center;
}

.FailedToRetrieveMsg p {
  box-shadow: 0.5rem 0.5rem rgba(0, 138, 193, 0.2);
  border-radius: 25px;
  padding: 5rem;
}

.DashboardHeaderContainer {
  position: fixed;
  width: 100%;
  z-index: 5;
}

.MobileView {
  display: none;
}

.DesktopView {
  display: block;
}

.LeftAlignContainer {
  padding: 0 6rem 0 3rem;
  max-width: fit-content;
}

@media (max-width: 650px) {
  .MobileView {
    display: block;
  }

  .DesktopView {
    display: none;
  }
}

/*- ////////////////////////////////// */

/* ////////  FONT STYLING  ///////// */
.SectionTitle {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gray-light-color);
}

.SubTitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0;
}

.SectionSubTitle {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0;
}

.SubText {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0;
}

/*- ///// TABLE STYLING ///////// */
div.ResourcesTable {
  padding: 1rem 0.5rem 0 0.5rem;
}

div.ResourcesTable div.nologs {
  position: relative;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding: 1rem 1rem 1rem 2rem;
  right: 1.6rem;
}

div.ResourcesTable.LoadingResourcesTable {
  flex-grow: 1;
}

.ResourcesTable table thead {
  text-transform: capitalize;
}

.ResourcesTable table {
  position: relative;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.ResourcesTable table th {
  padding: 1rem;
  text-align: left;
}


.ResourcesTable table th:first-child,
.ResourcesTable table td:first-child {
  text-align: left;
  padding-left: 1rem;
  text-transform: capitalize;
  border-top-left-radius: 5px;
}

.ResourcesTable table th:last-child,
.ResourcesTable table td:last-child {
  text-align: right;
  padding-right: 1rem;
  border-top-right-radius: 5px;
}

.ResourcesTable table td {
  padding: 0.8rem 1rem;
  text-align: left;
  font-weight: normal;
}

.ResourcesTable td {
  border: 1px solid var(--gray-light-color);
}

.ResourcesTable th {
  color: #fff;
  border: 1px solid white;
  border-top: none;
  border-bottom: none;
  background-color: var(--primary-color);
}

div.TableLoading {
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
}

tr.TableLoading {
  position: relative;
}

tr.TableLoading td {
  position: relative;
  left: 50%;
  top: 4rem;
  transform: translate(-50%, -50%);
}

.ResourcesTable table tr.TableLoading:hover,
.ResourcesTable table thead tr:hover {
  cursor: default;
  background-color: inherit;
}

.ResourcesTable table tr:hover {
  background-color: #d0e0f8;
  cursor: pointer;
}

.TableTdSpinner {
  border: none !important;
  height: 20vh;
}

.ResourcesTable td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70px;
}

.ResourcesTable td:hover {
  white-space: normal;
  word-wrap: break-word;
  overflow: visible;
}

/* remove outer borders */
.ResourcesTable tr:first-child td {
  border-top: none;
}

.ResourcesTable tr:last-child td {
  border-bottom: none;
}

.ResourcesTable td:first-child {
  border-left: none;
}

.ResourcesTable td:last-child {
  border-right: none;
}

.ResourcesTable th:first-child {
  border-left: none;
}

.ResourcesTable th:last-child {
  border-right: none;
}

.InnerTableSection div.ResourcesTable {
  padding: 0;
}

/*- ////////////////////////////////// */

/* Other */

.AdminNoResourcesMessage {
  display: flex;
  justify-content: flex-start;
  padding-left: 1rem;
  background-color: white;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
}

.NoResourcesMessage {
  flex-grow: 1;
  display: flex;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.TitleContainer .TitleArea {
  padding: 1rem 2rem 0 2rem;
}

/* //// ADMIN DASHBOARD PAGE STYLING  //// */

.MainPage {
  /* Divided into two rows */
  margin: 0;
  background: var(--gray-bg-light-color);
  height: 100vh;
  display: grid;
  grid-template-rows: 80px 4fr !important;
  overflow-x: hidden;
}

.TopBarSection {
  background-color: black;
}

.MainSection {
  /* divided into two columns */
  background-color: var(--gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  /* height: calc(100vh - 5rem); */
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
}

.FilterSection .SelectOptionsWrapper {
  overflow-y: auto;
}

.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.ContentSection .TitleArea {
  padding: 0 0 1rem 0;
}

/* MODAL REUSABLE STYLES */
.ModalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ModalTitle {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
  width: 100%;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ModalActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 2rem;
}

/*- /////////--- STYLING FOR FEEDBACK ---//////////// */
/* .ErrorOnWhite {
  color: rgb(0, 0, 0, 0.8);
  background-color: rgb(214, 59, 59, 0.5);
  text-align: center;
  padding: 0.7rem 0;
  border-radius: 0.4rem;
  border: 1px solid #ff0000;
  color: #310303;
}

.ErrorWhite {
  position: relative;
  color: #fff;
  background-color: #dc3545;
  text-align: center;
  padding: 0.7rem;
  padding-right: 3rem;
  border-radius: 0.4rem;
  border: 1px solid #ff0000;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}
 */

.FeedbackSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ErrorWhite {
  position: relative;
  color: #fff;
  background-color: #dc3545;
  text-align: center;
  padding: 0.7rem;
  padding-right: 3rem;
  border-radius: 0.4rem;
  border: 1px solid #ff0000;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}

.SuccessOnWhite {
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgb(0, 128, 0, 0.5);
  text-align: center;
  padding: 0.7rem;
  padding-right: 3rem;
  border-radius: 0.4rem;
  border: 1px solid #16a416;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}

.closeButton {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: 2px solid white;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  line-height: 1;
}

/* //////////////////////////////////////////// */

/* //////////--- PAGE STYLES ---//////////// */

.SmallContainer {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ShortContainer {
  padding-left: 4rem;
  padding-right: 2rem;
  max-width: 80%;
}

@media (min-width: 669px) {
  .SmallSelectElementValue {
    display: inline-flex;
  }
}

@media (max-width: 750px) {
  .SmallContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ShortContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* overflow: hidden; */
}

.TopRow {
  width: 100%;
}

.MainRow {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.FooterRow {
  padding: 3rem;
}

@media(max-width: 650px) {
  .MainSection {
    /*one column required */
    background-color: var(--gray-bg-light-color);
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .MainContentSection {
    background-color: var(--gray-bg-light-color);
    flex-direction: column;
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .Page {
    margin: 0;
    background: var(--gray-bg-light-color);
  }

  .LeftAlignContainer {
    padding: 0 1rem 0 1rem;
    max-width: fit-content;
  }

  .ContentSection {
    display: flex;
    padding-top: 2.5rem;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  .CloseMenuIcon {
    height: 1.8rem;
    width: 3rem;
    position: absolute;
    top: 1rem;
    justify-content: center;
    display: flex;
    z-index: 40;
  }

  .SpinnerWrapper {
    width: fit-content;
  }

}

.InformationBarTitle {
  display: flex;
  flex-direction: row;
}

.flex_back_link {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: #000 !important
}



/* //////////////////////////////////////////// */
/* ////////////---- CARDS ---- ///////////// */

.Card {
  background-color: var(--white-color);
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  transition: .3s;
}

.Card:hover {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
}

.breadcrumb {
  color: var(--primary-color);
  transition: all;
  text-decoration: none;
}

.breadcrumb:hover {
  font-size: 1.6rem;
}

.BigCard {
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 0.7rem;
  border: 1px solid var(--gray-light-color);
  height: fit-content;
}

.BigCard:hover {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.15);
}

.SmallCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 10rem;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.SmallCard:hover {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.15);
}

/* ////////////---- Pagination ---- ///////////// */
.AdminPaginationSection {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubTableContainer {
  padding-bottom: 0;
}

/*////////////// ---admin cards--- ///////// */

.ResourceClusterContainer {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AMainSection {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  max-width: fit-content;
}

.appFooterForSideBar {
  padding-left: 2rem;
}

.appFooterRow {
  padding: 1rem 3rem 0 6rem;
  color: black;
  position: relative;
  bottom: 0;
  background-color: var(--gray-bg-light-color);
}

.footerPositionAbsolute {
  position: absolute;
}

/* Modal buttons */
.AlertButtons {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 3rem;
  margin-bottom: 2rem;
}

.AlertDescription {
  cursor: default;
  font-weight: 700;
  font-size: 1.1rem;
}

.AlertDescription span {
  padding: 0.2rem 0.4rem;
  margin: 0;
  font-weight: bold;
  font-size: 90%;
  background-color: rgba(27, 31, 35, 0.1);
  border-radius: 3px;
}


@media only screen and (max-width: 740px) {
  .ResourceClusterContainer {
    align-items: center;
  }
}