.RegisterPageContainer {
  background: var(--primary-color) !important;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.RegisterContent {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 5rem;
}

.RegisterContentHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RegisterSuccessContent {
  max-width: 40rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}

.RegisterSuccessContent h2 {
  color: var(--secondary-color);
}

.RegisterSuccessContent p span {
  color: var(--secondary-color);
}

.RegisterTerms {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  padding: 1rem 0;
}

.RegisterTerms .Checkbox input[type="checkbox"] {
  opacity: 1;
}

.RegisterErrorDiv {
  background-color: rgba(255, 255, 255, 0.5);
  color: #d63b3b;
  text-align: center;
  padding: 1rem 1rem;
  border-radius: 5px;
}

.LogoIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.GitText {
  color: #fff;
  text-transform: capitalize;
}

.LowerSignupSection {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 0.5rem;
  gap: 0.5rem;
}

@media only screen and (max-width: 640px) {
  /* .RegisterContent {
    width: 90%;
  }

  .InputPassword,
  .InputText {
    width: 90%;
  }

  .VerificationCodeInput {
    width: 90%;
  }

  .VerificationCodeInput .InputText {
    width: 100%;
  } */
}

.password-wrapper {
  position: relative;
  display: grid;
  align-items: center;
}

.password-wrapper .toggle-password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-input-container {
  position: relative;
}

.password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-repeat {
  position: relative;
  display: grid;
  align-items: center;
}

.passwordConfirm.toggle-password {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.passwordConfirm {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}