.ClusterList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.ClusterCardItem {
  margin: 25px 25px;
  color: black;
}

.NoResourcesMessage {
  display: flex;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 7rem;
}