.TableHeader {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem 0 0;
}

.SectionTitle {
    display: flex !important;
    justify-content: space-between !important;
}

.SearchArea,
.SectionTitle {
    display: grid;
    align-items: center;
    padding-top: 1rem;
}

.RefreshButton:hover {
    cursor: pointer;
    display: inline;
}

.SpinnerArea {
    align-items: center;
    justify-items: center;
}

.CalendarSection {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.DateRangeSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.DateRangeButton {
    padding-top: 1rem;
}

.DateRangeSection:hover {
    cursor: pointer;
}
