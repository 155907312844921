
.NoResourcesMesssage {
  flex-grow: 1;
  display: flex;
  font-size: 0.9rem;
  padding: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.TopBarSection {
  background-color: black;
}

.MainSection {
  /* divided into two columns */
  background-color: var( --gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow: auto;
}

.SmallContainer {
  padding: 0 2rem;
}

@media (max-width: 580px) {
  .SmallContainer {
    padding: 0 1rem 1rem 2rem;
  }
}


.field {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  width: -webkit-fill-available;
  border-radius: unset;
}
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.Heading {
  font-weight: bolder;
  line-height: 1.8;
  font-size: larger;
}
.SimpleForm{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
}

.BackButton {
  background: var(--primary-color);
  outline: none;
}

.FilterButton {
  outline: none;
  background: unset;
  border: 1px solid black;
  color: #000;
}
.FilterButton:hover {
  border: none;
}

.TableRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.Success {
  color: green;
}

.Danger {
  color: red;
}

.Entity {
  color: var(--primary-color);
}

.hr {
  margin: 0.5rem 0;
}

.Row {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.ActivityEmail {
  font-weight: bold;
  font-size: 0.9rem;
}
.ActivityDate {
  font-size: 0.8rem;
  color: #555;
}


.RowCell {
  display: flex;
  gap: 1rem;
}

.ActivityDescription {
  font-size: 0.99rem;
  color: #555;
  display: flex;
  align-items: flex-end;
}
.UserAvatar {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Filter{
  width: 20rem;
  border: 1px solid #000000;
  display: flex;
  padding-left: 7px;
  padding-right: 7px;
  z-index: 1;
  background-color:  #fff;
  justify-content: space-between;
  align-items: center;
  border-radius: 9px;
  padding: .3rem;
}
.RelativeContainer{
  position: relative;
}
.BelowHeader {
  background-color: #f9f9f9;
  position: relative;
  left: -2rem;
  z-index: 999;
}
.contextMenu {
  color: #000;
  background-color: #f9f9f9;
  /* display: flex;
  flex-direction: column; */
  z-index: 1;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.FilterDropdown{
  width: 21rem;
  right: 0;
  overflow-y: auto;
  position: absolute;
  top: 3px;
  transition: all;
  z-index: 5;
  overflow-x: hidden;
  /* max-height: 23rem; */
  overflow: visible;
  border: 1px solid #000000;
  display: flex;
  background-color:  #fff;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
}
.DropdownArrowSvg {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-self: center;
  fill: black;
}
.FilterText{
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #555555;
}
.OnHeader:hover .DropdownArrowSvg {
  fill: var(--secondary-color);
}
.FilterItemContainer{
  width: 21rem;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
}
.FilterItem{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: .4rem 1rem .8rem 1rem;
  position: relative;
}
.OuterFilterItem{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: .3rem;
  align-items: center;
  background-color: #fff;
  width: 20rem;
  margin-right: 1rem;
  border-radius: 9px;
  border: 1px solid #000000;
}
.dateField{
  width: 6rem;
}
.InnerDropDown{
  position: absolute;
  width: 7rem;
  max-height: 15rem;
  background-color: #e2f5ff;
  z-index: 11;
  overflow-y: auto;
  overflow-x: hidden;
  right: 30px;
  border: 1px solid #ccc;
  top: 46px;
  display: flex;
  box-shadow: 0 4px 4px 0 rgb(117 117 117 / 20%);
  flex-direction: column;
}
.InnerDropDownLowerhalf{
  position: absolute;
  max-width: 10rem;
  max-height: 15rem;
  background-color: #e2f5ff;
  z-index: 11;
  overflow-y: auto;
  overflow-x: hidden;
  right: 37px;
  border: 1px solid #ccc;
  bottom: 46px;
  display: flex;
  box-shadow: 0 4px 4px 0 rgb(117 117 117 / 20%);
  flex-direction: column;
}
.InnerDropDownItem{
  display: flex;
  text-align: start;
  padding: 10px 10px 2px 10px;
  border-bottom: 1px solid #ccc;
}
.InnerDropDownItem:hover{
 cursor: pointer;
 background-color: #c1e6fa;
}
.SelectOption{
  font-size: 13px;
}
.SelectorBox{
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  padding: 2px 3px 2px 5px;
  margin-top: 3px;
  border-radius: 9px;
  border: 2px solid #000000;
}
.DateSection{
  padding: 2px 0px 2px 0px;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  gap: .5rem;
  margin-top: 3px;
}
.DateItem{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.DateSection:hover{
  cursor: pointer;
}
.CalenderFromposition{
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 0.5rem;
  display: grid;
  gap: 1rem;
  position: absolute;
  z-index: 10;
  left: 0;
  width: 45%;
  padding: 10px;
  border-radius: 10px;

}
.CalenderToposition{
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 0.5rem;
  display: grid;
  gap: 1rem;
  position: absolute;
  z-index: 10;
  left: 0;
  width: 45%;
  padding: 10px;
  border-radius: 10px;
}
.opacityClass{
  opacity: .2;
}
.errorSection{
  font-size: 11px;
  color: red;
}