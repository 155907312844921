.Checkbox {
  position: relative;
}

.Checkbox input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}

.CheckMarkWrapper {
  border: 2px solid #fff;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckMarkWrapper.CheckMarkWrapperBlack {
  border: 2px solid #000;
}

.CheckMarkWrapper svg {
  width: 20px;
  height: 20px;
  transition: opacity 0.18s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  opacity: 0;
}

.Checked-true svg {
  opacity: 1;
}

.Checked-true {
  background: inherit;
}
