.SelectWrapper {
  max-height: 3rem;
  width: 100%;
  display: grid;
  gap: 0.3rem;
  font-size: 0.8rem !important;
  font-weight: normal !important;
}

.SelectElementMain {
  border: 1px solid #000000;
  border-radius: 0.3rem;
}

.SelectElementValue {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.SmallSelectElementValue {
  padding: 2px;
  display: flex;
  justify-content: space-between;
}

.SelectOptionsWrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border: 1px solid #8e8e8e;
  border-radius: 0 0 0.4rem 0.4rem;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  max-height: 250px;
  overflow-y: auto;
}

.SelectOptionsWrapper .SelectOption {
  padding: 0 1rem;
  line-height: 2.5rem;
  height: 2.5rem;
  display: flex;
}

.SelectOptionsWrapper .SelectOption:hover {
  background-color: var(--gray-bg-light-color);
  cursor: pointer;
}

.SelectIcon {
  margin-right: 8px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.SelectArrow {
  transition: all 400ms;
  display: flex;
  align-items: center;
}

.SelectArrow svg {
  width: 0.7rem;
  height: 0.7rem;
}

.SelectArrow.SelectArrowShowOptions {
  transform: rotate(180deg);
}