.ProjectDeleteModel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}

.ProjectDeleteModel .ModalParentWrap {
  background-color: inherit;
}

.ProjectDeleteModel .ModalChildWrap {
  z-index: 3;
}

.DeleteProjectModel {
  display: grid;
  gap: 3rem;
}

.DeleteProjectModalLowerSection {
  display: grid;
  gap: 1rem;
}

.DeleteDescription {
  cursor: default;
  font-weight: 700;
  font-size: 1.1rem;

}

.ProjectInputTag div div{
  display: flex;
  place-items: center;
}

/* .UpdateInputSection , .DeleteDescription , .ProjectInputTag .tagInputContainer  .tagList{
  max-width: 20rem;
} */
.DeleteDescription span {
  padding: 0.2rem 0.4rem;
  margin: 0;
  font-weight: bold;
  font-size: 90%;
  background-color: rgba(27, 31, 35, 0.1);
  border-radius: 3px;
}

.DeleteProjectModelButtons {
  display: flex;
  justify-content: space-between;
}

.UpdateProjectModelButtons {
  display: flex;
  justify-content: space-between;
}

.ModalUpdateForm {
  display: grid;
  gap: 2rem;
}

.ModalUpdateForm .ModalFormInputs {
  display: grid;
  gap: 1rem;
}

.ModalUpdateForm .ModalFormInputs .InputText {
  border: 2px solid #000000;
  color: #000;
}

.ModalUpdateForm .ModalFormInputs .InputText::placeholder {
  color: #000;
}

.ModalUpdateForm .ModalFormButtons {
  display: flex;
  justify-content: space-between;
}

.ProjectDeleteModel .ModalChildWrap {
  min-width: 21rem;
  max-width: 25rem;
}

.UpdateForm {
  display: grid;
  gap: 1rem;
  padding: 1rem 1.3rem;
  grid-template-rows: 2fr;
}

.UpdateInputSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}


.ErrorOnWhite {
  width: 25rem;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
  height: 100vh;
  display: grid;
  grid-template-rows: 80px 4fr;
  /* overflow-x: hidden; */
}

.MainSection {
  /* divided into two columns */
  background-color: var(--gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow: auto;
}

.TopBarSection {
  background-color: black;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
}

.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.InnerModalDescription {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.ProjectsDetailsInnerSection{
  display: grid;
  grid-template-columns: 2fr 2fr 2fr ;
  grid-template-rows: auto;
  overflow-x: auto;
  width: -webkit-fill-available;
  padding-top: 1rem;
}

.ProjectsDetailsInnerSection > :nth-child(1) {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
}

.ProjectsDetailsInnerSection > :nth-child(2) {
  display: grid;
  justify-content: center;
  align-items: center;
}

.ProjectsDetailsInnerSection  > :nth-child(3) {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  /* text-align: end; */
  padding-right: 1rem;
}

.ProjectsDetailsInnerSection > *:not(:last-child) {
  border-right: 1px solid var(--gray-light-color);
}

.InnerContentGrid div {
  padding: 0 0 0.3rem 0;
}


.DeleteWarning {
  color: #f00;
}

.InactiveDelete {
  background-color: #fff;
  border: 1px solid #ff9595;
  color: #ff9595;
  min-width: 8rem;
}

.InactiveDelete:hover {
  background-color: #fff;
  border: 1px solid #ff9595;
  color: #ff9595;
}

.WarningContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DeleteSubDescription {
  font-weight: 500;
}

.DisableSubDescription {
  font-weight: 500;
  width: 20rem;
}

.MembershipHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.MemberSection {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0.5rem;
}

.MemberDescription {
  width: 30rem;
}


.ProjectButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.ProjectButtonRow>div {
  cursor: default;
}


.CopyIcon {
  cursor: pointer !important;
  display: flex;
  gap: 0.3rem;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}

.ProjectButtonRow:hover .CopyIcon {
  visibility: visible;
}

.SettingsSectionInfo {
  font-weight:400;
}

.SectionButtons {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.ProjectSections {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SettingsSectionInfoHeader {
  font-weight: 600;
  /* font-size: 1.1rem; */
}

.TokenItem {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 20rem;
}


.DeleteBtn {
  color: #fff;
  background-color: #ff0000;
  border: 1px solid #ff0000;
  outline: none;
  min-width: 8rem;
}

.DeleteBtn:hover {
  background-color: #fff;
  border: 1px solid #ff0000;
  color: #ff0000;
}

.MemberTableHead {
  text-transform: uppercase;
  color: var(--primary-color);
  text-align: center;
  grid-row: 1;
  padding: 0.5rem 0;
}

.MemberNameEmail {
  display: flex;
  flex-direction: column;
  gap: .13rem;
  font-weight: 400;

}

.UserHeader {
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1rem;
}

.UserHeader:hover {
  text-decoration: underline;
}

.Wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  align-items: center;
  /* max-width: 300px; */
  white-space: nowrap;
}

.Wrap:hover {
  word-wrap: break-word;
  overflow: visible;
  cursor: pointer;
  white-space: normal;
}

.MemberBody {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--gray-light-color);
}

.MemberTableRow {
  display: flex;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  margin: 0;
  background-color: white;
  gap: 1rem;
}

.MemberActionArea{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4rem;
  align-items: center;
}

.MemberInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: center;
}

.MemberOptions {
  display: grid;
  grid-template-columns: repeat(2, minmax(7rem, 1fr));
  gap: 2rem;
  align-items: center;
}

.MemberRole {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.3rem;
  align-items: baseline;
  font-weight: 600;
  color: var(--primary-dark-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.MemberRole:hover {
  color: var(--primary-color);
  transform: scale(1.1);
}

.MemberRole span {
  font-size: 0.9rem;
  font-weight: 500;
}

.MemberTableRowUnregistered {
  display: grid;
  color: #000000;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  background-color: white;

}

.NameSecting {
  display: flex;
  justify-content: flex-start;
  gap: .4rem;
  align-items: center;
}

.MemberAvatar {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.MemberTableRow:not(a) {
  border-bottom-width: 2px;
}

.CardBodySection .MemberTableRow {
  width: 100%;
}

.SendInvitationButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlueBtn:hover {
  color: var(--primary-color);
  background-color: #fff;
  outline: 2px solid var(--primary-color);
}

.BlueBtn {
  color: #fff;
  background-color: var(--primary-color);
  outline: 2px solid var(--primary-color);
  padding: 0.5rem;
  height: 2.5rem;
}

.ModelHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 2rem;
}

.SettingsButton {
  background-color: var(--primary-color);
  color: #fff;
  outline: 1px solid var(--primary-color);
}

.SettingsButton:hover {
  color: var(--primary-color);
  background-color: #fff;
  outline: 1px solid var(--primary-color);
}

.SettingsButtonUpdate {
  color: #fff;
  background-color: var(--secondary-color);
  outline: 1px solid var(--secondary-color);
}

.SettingsButtonUpdate:hover {
  color: var(--secondary-color);
  background-color: #fff;
  outline: 1px solid var(--secondary-color);
}

.contextMenu {
  color: #000;
  background-color: #f9f9f9;
  /* display: flex;
  flex-direction: column; */
  z-index: 1;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.MoreIcon:hover {
  cursor: pointer;
}

.MoreIconHidden {
  display: none;
}

.BelowHeader {
  background-color: #f9f9f9;
  position: relative;
}

.DropDownLink {
  cursor: pointer;
  padding: 1rem 1.5rem;
  text-align: center;
}

.DropDownLink:hover,
.DropDownLink:active {
  background-color: var(--gray-bg-light-color);
}

.DropDownLink,
.DropDownLink:visited {
  color: #000;
  text-decoration: none;
}

.MemberTableCell {
  font-weight: normal;
  padding: 1rem 0;
  text-align: center;
  grid-row: 0;
  flex: 1;
}

/* .MemberTableCell div {
 
} */

.MemberTableHead:first-child,
.MemberTableCell:first-child {
  text-align: left;

}

/* .MemberTableCell:first-child {
  text-transform: uppercase;
} */

.MemberTableHead:last-child,
.MemberTableCell:last-child {
  text-align: right;
  padding-right: 1rem !important;
}

.OptionButtons {
  display: flex;
  gap: 1rem;
}
.SectionSubTitle{
  font-size: 0.9rem;
  font-weight: 550;
}



.SendButton,
.BinButton {
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray-light-color);
  border-radius: 0.5rem;
  padding: 10px;
}

.SendButton:hover,
.BinButton:hover {
  cursor: pointer;
}

.SendButton {
  background-color: var(--primary-color);
}

.BinButton {
  background-color: #d63b3b;
}

@media(max-width: 650px) {
  .MainSection {
    /*one column required */
    background-color: var(--gray-bg-light-color);
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .MainContentSection {
    background-color: var(--gray-bg-light-color);
    flex-direction: column;
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .UpdateForm {
    max-width: 20rem;
  }

  .UpdateInputSection {
    width: 20rem;
  }

  .TextArea {
    width: 20rem;
    border: 1px solid black;
    box-sizing: border-box;
    font-size: inherit;
    padding: 1rem;
  }
  .MembershipHeader {
    flex-direction: column-reverse;
    align-items: start;
  }
  .MemberAvatar {
    display: none;
  }
  .ProjectInstructions {
     min-width: 44rem
  }

}