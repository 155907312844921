.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
  width: 100%;
}

.MSFWidth {
  width: 80%;
  margin: auto;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  /* overflow: hidden; */
}

.ContentSection {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 0rem 2rem;
  padding-left: 3rem;
  overflow: auto;
}

.FeedbackSection {
  display: flex;
  padding-top: 0.5rem;
  align-items: center;
  justify-content: center;
}

.ModalFormInputs {
  background: #ffffff;
  padding: 1rem;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

.WhiteBackground {
  background-color: #fff;
  padding: 1rem;
}

.MSAAdd {
  margin: 0rem 1rem 1rem 1rem;
  border: 1px solid blue;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.Inliner {
  display: inline;
}

.MSAApp {
  margin: 0rem 0rem 1.5rem 1rem;
}

.BackDiv img {
  display: inline;
}

.BackDiv {
  display: inline;
  color: black;
}

.CenterForm {
  margin: auto;
  width: 70%;
}

.BackDiv p {
  display: inline;
}

.BackDiv img:hover {
  transition: 0.2s;
  transform: scale(1.2);
}

.ModalForm.AddAppModal .ModalFormHeading {
  margin-bottom: 0;
}

.ModalFormInputsBasic {
  display: grid;
  gap: 1rem;
}

.ModalFormInputsBasic input::placeholder {
  color: gray;
}

.EnvVarsInputGroup {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.5rem;
}

.EnvVarsInputGroup .EnvVarsInputs {
  width: 70%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.EnvVarsInputGroup .EnvVarsAddBtn {
  position: relative;
  display: flex;
  align-items: center;
}

.EnvVarsInputGroup .EnvVarsAddBtn button.EnvVarAddBtn {
  position: absolute;
  max-width: 100%;
  height: 2.5rem;
  color: #fff;
  background-color: var(--primary-color);
  padding: 1rem;
}

.EnvVarsInputGroup .EnvVarsAddBtn button.EnvVarAddBtn:hover {
  color: var(--primary-color);
  background-color: #fff;
}

.EnvVarsInputGroup input {
  max-width: 10rem;
}

.EnvVarsInputGroup input::placeholder {
  color: grey;
}

.EnvVarsTable {
  width: 100%;
  margin-bottom: 1rem;
}

.EnvVarsTable table {
  text-align: left;
}

.EnvVarsTable table thead td {
  font-weight: normal;
  color: #555;
}

.EnvVarsTable table thead td:last-child {
  width: 4rem;
  text-align: right;
}

.EnvVarsTable table td:last-child {
  width: 4rem;
  display: block;
  margin: auto;
}

.envFileInput {
  resize: none;
  transition: all;
  width: 98%;
}

.EnvVarsTable table td {
  font-weight: normal;
}

.EnvVarsTable table td img {
  margin: auto;
}

.EnvVarsTable table td img:hover {
  /* cursor: pointer; */
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(180deg) brightness(119%) contrast(5%);
}

.EnvVarsTable thead,
.EnvVarsTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/*- //////////////////////////////////////////////////// */

.ModalForm.AddAppModal {
  display: grid;
  gap: 2rem;
  height: 100%;
}

.ModalForm.AddAppModal input {
  height: 3.1rem;
}

.ModalForm.AddAppModal .ModalFormHeading {
  flex: 0 1 auto;
}

.ModalForm.AddAppModal .ModalFormInputs {
  padding-right: 10px;
  flex: 1 0 auto;
  overflow-y: auto;
}

.ModalFormInputs::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.ModalFormInputs::-webkit-scrollbar-track {
  background: var(--gray-bg-light-color);
}

.ModalFormInputs::-webkit-scrollbar-thumb {
  background: #555;
}

.ModalFormInputs::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.HeadingWithTooltip {
  display: flex;
}

.DbSupportSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0%;
  padding-bottom: 1rem;
}

.HeadingWithTooltip h4 {
  margin: 0;
  padding: 0.5rem 0;
}

.DbSupportCheckField,
.PrivateImageCheckField,
.CustomDomainCheckField {
  display: flex;
}

.InputFieldWithTooltip {
  /* width: 60%; same as input field */
  display: flex;
  position: relative;
}

.InputTooltipContainerDB {
  position: absolute;
  right: 0%;
  padding-right: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.InputTooltipContainer {
  position: absolute;
  right: 0%;
  padding-right: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.DatabaseSupportTabContainer,
.PrivateImageTabContainer,
.CustomDomainTabContainer {
  max-width: 60%;
  /* this is to correspond with the width of the input elements */
}

.PrivateImageInputs,
.DatabaseSupportInputs,
.CustomDomainInputs {
  display: grid;
  gap: 0.5rem;
}

.PrivateImageInputs input,
.DatabaseSupportInputs input {
  width: -webkit-fill-available;
}

.DbCheckBoxInputGroup {
  display: grid;
  gap: 1rem;
}

.ConnectModal {
  width: 82.19%;
  margin-left: 17.81%;
}

.InformationBar {
  background: #f8f7e5;
  height: 5rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
}

.InformationBarWithButton {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.InfoHeader {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.FormHeading {
  padding: 0.3rem 0rem;
  font-weight: 600;
}

.ErrorOnWhite {
  width: 100%;
}

.ClusterSelectionSection {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  flex-direction: row;
  color: rgb(85, 85, 85);
}

.TooltipStyles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.alignText {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ClusterToggleSection {
  display: flex;
  padding: 0rem;
  flex-direction: row;
  font-size: 13px;
  text-align: center;
  align-items: center;
}

.ClustersSection {
  display: flex;
  padding: 0 4rem;
  flex-direction: column;
  font-size: 13px;
  color: #000000;
}

.MultiSelectionText {
  padding-bottom: 1rem;
}

li.ListStyle {
  list-style-type: none;
}

.leftsection {
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: fit-content;
}

.ClusterLabel {
  display: flex;
  padding-left: 1rem;
}

.DeploymentMethodTabs {
  padding: 1rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}

.CurrentTab {
  background-color: #ffffff;
  padding: 1rem 0.5rem;
  font-weight: bold;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.CurrentTab:hover,
.Tab:hover {
  cursor: pointer;
}

.Tab {
  padding: 0.5rem;
  font-weight: bold;
  line-height: 2.6;
}

.ButtonSection {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 650px) {
  .RoundAddButtonWrap {
    position: absolute;
    top: 10rem;
    right: 1rem;
  }

  .ContentSection {
    padding-top: 2.5rem;
  }

  .MainContentSection {
    background-color: var(--gray-bg-light-color);
    flex-direction: column;
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }
}