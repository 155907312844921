.OverviewContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.TitleArea {
  padding: 0 2rem;
}

.APage {
  margin: 0;
  background: var(--gray-bg-light-color);
}




.AChartsArea {
  width: 100%;
}

.TitleArea .XSearchBar {
  padding-bottom: 0.5rem;
}

.TitleArea .XSearchBar .AdminSearchInput input {
  padding: 1.5rem;
}

.TitleArea .XSearchBar .AdminSearchInput .SearchIcon {
  height: 49px;
}

.VisualArea .SectionTitle {
  font-weight: bold;
  border-bottom: none;
}

.ChartContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0.5rem;
  gap: 20px;
}

.VisualArea {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.VisualAreaHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.VisualAreaText {
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.PieContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ChartColumn {
  flex: 1;
}

.PercentageColumn {
  display: contents;
}

.KeyItems {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ProjectsInformationBarTitle {
  display: flex;
  gap: 2rem;
}

.APage .appFooterRow {
  padding: 0rem 3rem 0 4.5rem;
}