.ToggleButtonContainer {
  width: 3.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ToggleBase {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: #eeeeee;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.ToggleButtonSlider {
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  background-color: #cccccc;
  border-radius: 50%;
}

.ToggleButtonSlider.ToggleOn {
  left: 2px;
}

.ToggleButtonSlider.ToggleOff {
  right: 2px;
}
