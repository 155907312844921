.NoResourcesMessage {
    flex-grow: 1;
    display: flex;
    font-size: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 12rem;
}

.Page {
    background-color: #f1f1f1;
    position: relative;
}

.OverviewContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.BackButton {
    width: fit-content;
    color: var(--primary-color);
    height: 2rem;
    background-color: #fff;
    border: 1px solid var(--primary-color);
}

.BackButton:hover {
    color: #fff;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.FooterRow {
    padding: 1rem 3rem;
    background-color: var(--gray-bg-light-color);
    position: absolute;
    bottom: 10px;
    left: 0;
}

.UserContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    max-width: 51rem;
}

.SecondMainSection {
    flex: 1;
}

.UserAvatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.UserAvatarLarge {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CardInfor {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 0.95rem;
    gap: 0.5rem;
    color: #4d4d4d;
}

.CustomInput {
    height: 2.5rem;
    padding: 0.3rem;
    width: 70%;
}

.CustomPasswordInput {
    border: 1px solid #000;
    height: 2.5rem;
    padding: 0.3rem;
    width: 70%;
}

.InputDiv {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 0.3rem 0.3rem 0.3rem 0;
}

.PasswordChange {
    display: flex;
    align-items: center;
    gap: 4em;
    width: 100%;
    padding: 0.3rem 0.3rem 0.7rem 0.3rem;
}

.ExtraContentDiv {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 0;
}

.ContainerCard {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    gap: 0.4rem;
    flex-direction: column;
    border-radius: 10px;
    padding: 1rem 2rem;
    background-color: #fff;
}

.ButtonsDiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.UserSectionTitle {
    font-weight: 700;
    font-size: 24px;
    color: #000;
}

.UserSectionSubTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    color: #000;
}

.ProfileActionBtns {
    display: flex;
    justify-content: flex-end;
}

.ContainerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(204, 204, 204, 0.69);
    margin-bottom: 1rem;
}

.ContainerHeadSection {
    /* display: flex; */
    border-bottom: 1px solid rgba(204, 204, 204, 0.69);
    padding-bottom: 2rem;
}

/*increased width to 48rem from 30rem*/
.ProfileCard {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border-radius: 10px;
    background-color: #fff;
    padding: 1.3rem 1rem 1rem 2.5rem;
}

.UserProfileCard {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    border-radius: 10px;
    background-color: #fff;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
}

.Identity {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.IdentityName {
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
    display: flex;
    align-items: center;
    gap: 3rem;
    text-transform: capitalize;
}

.EmailHead {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    align-items: flex-start;
}

.FeedBackDiv {
    color: rgb(28, 255, 28);
}

.ErrorDiv {
    color: red;
}

.BetaUserDiv {
    display: inline;
    padding: 0.3rem 0.5rem;
    border-radius: 999px;
    background-color: rgba(128, 0, 128, 0.217);
    color: purple;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: normal;
}

.IdentityEmail {
    color: #686868;
    font-size: 0.95rem;
}

.HeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.UserProfileInfoHeader {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    position: relative;
}

.DateStyles {
    position: absolute;
    color: #555;
    font-size: 12px;
    text-align: left;
    top: 0;
    right: 0;

}

.dateStyle {
    font-weight: 600;
}

.FollowButton {
    margin-top: 10px;
    width: fit-content;
    padding: 7px;
    margin-bottom: 5px;
}

/* Projects */

.ProjectsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    /* min-width: 80vh; */
}

.FollowProjectCard {
    background-color: #F8F7E5;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 15px 10px 15px 10px;
    text-align: left;
}

.ProjectName {
    font-weight: bold;
    margin-bottom: 2px;
}

.ProjectDescription {
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FollowButtonProjects {
    background-color: #D9D9D9;
    border: 1px solid transparent;
    padding: 1px 12px;
    border-radius: 0px;
    min-width: 6.5rem;
    cursor: pointer;
}

.FollowButtonProjects:hover {
    background-color: #e0e0e0;
}


.ProfileProjectsSection {
    margin-bottom: 3rem;
    position: relative;
    width: 100%;
    min-height: 17rem;
}
.TopProjectsContainer{
   min-width: 80vh;
   width: 100%;
   margin-top: -10px;
}

.ShowMoreProjectsContainer {
    position: absolute;
    color: #008AC1;
    margin-top: 15px;
    right: 0;
    cursor: pointer;
}

.ShowMoreProjectsLink {
    display: flex;
    gap: 5px;
}

/* Activities.css */


.ActivityMonth {
    margin-bottom: 20px;
    position: relative;
    padding-left: 20px;
}

.ActivityMonthTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2.5rem;
    position: relative;
    z-index: 5;
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
}

.ActivityMonthTitle::after {
    content: "";
    position: absolute;
    width: 75%;
    height: 1px;
    bottom: 50%;
    left: 8rem;
    background-color: #000;

}

.ActivityMonth:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 90%;
    background-color: #000;
    left: 45px;
    top: 30px;
    z-index: 1;
}

.ActivityItem {
    margin-bottom: 10px;
    padding: 5px;
}

.ActivityItemHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 2rem;
}

.ActivityExpandIcon {
    margin-right: 10px;
    transition: transform 0.2s ease;
}

.ActivityExpandIcon.expanded {
    transform: rotate(180deg);
}

.ActivityType {
    margin-right: 10px;
    z-index: 5;
    padding-top: 10px;
    background-color: #fff;
    padding-bottom: 10px;
}

.ActivityDescription {
    flex-grow: 1;
    margin-left: 10px;
}

.ActivityDetails {
    margin-top: 10px;
    padding-left: 4rem;
}

.ActivityProject {
    display: flex;
    /* justify-content: space-between; */
    gap: 1rem;
    margin-bottom: 5px;
    /* margin-left: 2rem; */

}

.ActivityProjectName {
    color: #0073e6;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
}

.ProjectApps {
    color: #666;
    font-size: 12px;
}

.ActivitiesContainer {
    background-color: #fff;
    margin-top: 1rem;
    border: 1px solid #ddd;
    padding: 10px 10px 25px 20px;
    border-radius: 5px;
}

.ShowMoreButton {
    width: 100%;
}

.ActivitiesHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    padding-bottom: 5px;
    font-size: 20px;
    border-bottom: 1px solid #ccc;
}

.YearFilter {
    padding: 10px;
    z-index: 10;
    font-size: 15px;
}

/* .selectComponent{
   
} */
/* tabs */

.tabsContainer{
    /* display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    max-width: 50%;
    margin-top: 20px; */
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 20px;
    width: 100%;
    padding-bottom: 5px;
    /* border-top: 1px solid #ccc; */
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    padding-top: 10px;
    z-index: 20;
    background-color: #e5ebf3;

}

.TabOptionLayout{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 3rem;
}
.TopProfileCardContainer{
    width: 30%;
    position: sticky;
    top: 10px;
    height: fit-content;
}

@media (max-width: 500px) {}

@media only screen and (max-width: 900px) {}

@media only screen and (max-width: 700px) {
    .TabOptionLayout{
        flex-direction: column;
    }
    .ContainerSection {
        display: flex;
        flex-direction: column;
        width: auto;
    }
    /* .TopProjectsContainer{
        width: 90%;
    } */
    .TopProfileCardContainer {
        width: 100%;
    }
    .ProfileProjectsSection {
        width: 90%;
    }

    .Page {
        min-width: 40rem;
        height: 180vh;
    }
    .ProjectsContainer {
        grid-template-columns: 1fr;
    }

    .UserAvatar {
        display: none;
    }

    .BetaUserDiv {
        display: none;
    }

    .Identity {
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .IdentityName {
        display: flex;
        flex-wrap: wrap;
    }

    .ActivityMonthTitle::after {
        display: none;
    }


    .DateStyles{
        display: none;
    }
    .FollowButton{
        position: absolute;
        color: #555;
        font-size: 12px;
        text-align: left;
        top: 0;
        right: 0;
    }
    .UserProfileInfoHeader {
       flex-direction: column;
    }
}