.SideNav {
  width: 100%;
  height: 100vh;
  background: var(--primary-dark-color);
  mix-blend-mode: normal;
  /* margin-left: 0px; */
  overflow-x: hidden;
}

.ClusterName {
  background-color: var(--primary-dark-color);
  font-weight: bold;
  font-size: large;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SideNavTopSection {
  padding: 2rem 1rem;
  display: flex;
  gap: 0.5rem;
  transition: .3s;
  align-items: center;
  cursor: pointer;
  color: white;
}

.SideNavTopSection:hover {
  transform: scale(1.1);
}

.StickTop {
  position: sticky;
  top: 0;
}

.ClusterName a {
  color: #fff;
}

.ClusterName .CName:hover {
  text-decoration: underline;
}

.ClusterName a img {
  display: inline;
  height: 13px;
}

.ClusterName a img:hover {
  transition: 0.2s;
  transform: scale(1.5);
}

.SideNav::-webkit-scrollbar {
  display: none;
}

.active {
  color: var(--primary-dark-color) !important;
  background-color: var(--primary-light-color);
  padding-left: 20px;
}

.ListItem {
  height: 2.5rem;
  background-color: var(--primary-dark-color);
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  /* line-height: 19px; */
  color: var(--secondary-color);
  margin-top: 2rem;
  cursor: pointer;
  padding-left: 1.5rem;
  text-transform: capitalize;
  display: block;
}

.SubListItem {
  /* height: 21px; */
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  /* line-height: 19px; */
  color: var(--primary-light-color);
  padding: 1rem 1.5rem;
  cursor: pointer;
  display: block;
  text-transform: capitalize;
}

.List {
  height: max-content;
  overflow: auto;
}

.SideFooter {
  height: 30px;
  font-size: 12px;
  line-height: 14px;
  bottom: 0;
  color: var(--primary-color);
  padding: 18px 0 20px 18px;
  cursor: pointer;
  background-color: var(--primary-dark-color);
}

.SideFooter:hover {
  color: rgb(131, 182, 202);
}

.ListItem:hover {
  text-shadow: 0 0 0.5px rgb(255, 194, 62);
}

.SubListItem:hover {
  background-color: rgb(13, 60, 82);
  color: rgb(164, 228, 253);
}

.MenuIcon {
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: .5rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  justify-content: center;
  display: flex;
  z-index: 40;
}


.SmallSidebar {
  height: 100vh;
  background: var(--primary-dark-color);
  mix-blend-mode: normal;
  margin-left: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: fixed;
}