.ResetPasswordPageContainer {
  flex-grow: 1;
  background: var(--primary-color)!important;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.ResetPasswordContent {
  flex-grow: 1;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ResetPasswordContentInputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

a.ResetPasswordContentLink {
  color: var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
}

.ResetPasswordMessage {
  align-self: center;
  justify-self: center;
}

.ResetPasswordSuccessContent {
  min-height: 25rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
.ResetPasswordSuccessContent h2 {
  color: var(--secondary-color);
}

.ResetPasswordSuccessContent p span {
  color: var(--secondary-color);
}

.ResetPasswordErrorDiv {
  background-color: rgba(255, 255, 255, 0.5);
  width: 25rem;
  color: #d63b3b;
  text-align: center;
  padding: 1rem 0;
}

@media only screen and (max-width: 640px) {
  .ResetPasswordContent {
    width: 90%;
  }

  /* .InputText {
    width: 90%;
  } */
}
