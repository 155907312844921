.ProgressBarContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.ProgressBar {
  position: relative;
  height: 20px;
  width: 70%;
  background-color: #dddddd;
}

.ProgressBarFiller {
  height: 100%;
  border-radius: inherit;
  transition: width 0.5s ease-in;
  color: white;
  text-align: center;
}

.ProgressBarFillQuarter1 {
  background-color: #ff0000;
}

.ProgressBarFillQuarter2 {
  background-color: #d2741d;
}

.ProgressBarFillQuarter3 {
  background-color: var(--secondary-color);
}

.ProgressBarFillQuarter4 {
  background-color: #98a058;
}
