.context-menu {
  color: #000;
  background-color: #f9f9f9;
  position: absolute;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.XSearchBar{
  padding-top: 1rem;
}
.AdminSearchInput {
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  align-items: center;
  height: 2.5rem;
  border-radius: 5px;
}

.AdminSearchInput input {
  background-color: #ebead5;
  border: 0;
  border-top-left-radius: 4px 2px;
  border-bottom-left-radius: 3px 4px;
  color: #000;
  font-size: 0.9rem;
  height: 40px;
  max-width: 21rem;
  padding: 1rem;
}

.SearchIcon {
  background-color: #EBEAD5;
  height: 100%;
  padding: 0 0.4rem;
  width: 1.3rem;
}

.BetaUser {
  display: flex;
  gap: 1rem;
}

.BetaUserText {
  text-align: center;
  font-size: 18px;
}

.creditCoin {
  width: 1rem;
  height: 1rem;
}

.creditSection {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: .4rem;
}

.DropDownLink a {
  color: inherit;
}