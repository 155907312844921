.StatusModule {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  .StatusModule:hover {
    background-color: #f1f1f1;
  }
  
  .LeftContent {
    flex: 1;
  }
  
  .RightContent {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
  
  .StatusSectionCardTitle {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  
  .Description {
    margin-top: 8px;
    padding-left: 16px;
    color: #555;
  }
  .Tooltip {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .StatusSectionCardTitle .TooltipPlaceholder {
    color: black;
  }
  
  @media only screen and (max-width: 750px) {
    .StatusModule {
      flex-direction: column;
    }
  
    .RightContent {
      margin-left: 0;
      margin-top: 10px;
    }
  }