.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.Title {
    font-size: 2rem;
    font-weight: bold;
}

.Description {
    font-size: 14px;
    color: #747272;
    padding-left: 0.5rem;
}

.RightDashboardButtons {
    display: flex;
    gap: 2rem;
}

.FiltersSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.createExperimentActionButtonsContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px
}

.SearchBar {
    padding: 0.5rem;
    width: 40%;
    margin-right: 26px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.SelectGroup {
    display: flex;
    gap: 20px;
    width: 60%;
}

.SelectGroup:hover {
    cursor: pointer;
}

.success {
    color: green;
    font-weight: bold;
}

.error {
    color: red;
    font-weight: bold
}

.rowHover {
    background-color: #f9f9f9;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
}

.tabs>button {
    border-radius: 0;
}

.noDataSection>p {
    font-size: 0.9rem;
    margin-top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: hidden;
    height: 30vh;
}

.activeTab {
    border-bottom: 4px solid var(--primary-color);
    font-weight: bold;
}

/* .content {
    font-family: monospace;
    background: #f9f9f9;
    padding: 10px;
    overflow-x: auto;
} */
.content {
    overflow-y: auto;
    /* Enable vertical scrolling */
    flex-grow: 1;
    /* Allow content to take available space */
    padding: 0 20px;
}

/* Add this to your styles file */
.keySteps {
    background-color: #f7f9fc;
    border-left: 4px solid #4285f4;
    border-radius: 4px;
    padding: 16px 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.keySteps h3 {
    color: #333;
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: 600;
}

.keySteps ol {
    margin: 0;
    padding-left: 24px;
}

.keySteps li {
    margin-bottom: 8px;
    line-height: 1.5;
}

.keySteps code {
    background-color: #e8eef7;
    padding: 2px 6px;
    border-radius: 3px;
    font-family: monospace;
    font-size: 0.9em;
    color: #2c5282;
}

/* Optional: style the description text after the code */
.keySteps li::after {
    content: attr(data-desc);
    color: #666;
    font-size: 0.9em;
    margin-left: 6px;
}

/* Code container with header */
.codeContainer {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #eaeaea;
}

/* Code header with copy button */
.codeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f3f5;
    padding: 8px 12px;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
    color: #555;
}

/* Copy button */
.copyButton {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 4px 8px 4px 28px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #555;
    position: relative;
}

.copyButton::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='9' y='9' width='13' height='13' rx='2' ry='2'%3E%3C/rect%3E%3Cpath d='M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1'%3E%3C/path%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.copySuccess::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%234CAF50' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

.copyButton:hover {
    background-color: #f7f9fc;
    border-color: #d0d0d0;
}

.copySuccess {
    color: #4CAF50;
}

/* Updated pre/code styling */
pre {
    margin: 0;
    padding: 16px;
    background-color: #f6f8fa;
    overflow-x: auto;
}

code {
    font-family: monospace;
    white-space: pre;
    display: block;
}

.NoResourcesMessageSection {
    flex-grow: 1;
    display: flex;
    height: 50vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 6rem;
}

.NoResourcesMessage {
    font-size: 16px;
    text-align: center;
    justify-content: center;
}

.ButtonPlusSmall {
    display: inline;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}