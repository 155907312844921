.projectCard {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffff;
    padding: 20px;
    border-radius: 8px;
    margin: 10px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.appCount{
    position: absolute;
    top:0px;
    right: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    border-radius: 0.4rem 0.4rem 0 0;
   }
   
.appCount>div{
     padding:0.1rem .5rem;
     border-radius: 0 0.1rem 0 0;
     background-color: rgb(235, 235, 235);
     color: #000;
   }

.cardContent {
    display: flex;
    flex-direction: column;
}

.cardSummary {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.title {
    font-size: 1em;
    font-weight: 600;
    margin: 0;
    color: var(--primary-color);
    text-transform: capitalize;
}

.title:hover {
    cursor: pointer;
    text-decoration: underline;
    transform: scale(1.05);
}

.description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    min-height: 2.3em;
    margin: 5px 0 0 0;
}

.numberBox {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fafae5;
    padding: 2px;
    border-radius: 8px;
    min-width: 40px;
    display: none;
    justify-content: center;
    align-items: center;
}

.numberBox:hover {
    cursor: pointer;
}

.number {
    font-size: 1em;
}

.cardExtras {
    display: flex;
    padding-top: 0.3rem;
}

.membersIcon {
    width: 1rem;
    height: 1rem;
}

.memberCount {
    padding-left: 0.5rem;
}

.followButtonArea {
    position: absolute;
    bottom: 20px;
    right: 10px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.followButton {
    background-color: var(--secondary-color);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
}

.stats {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.statItem {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.statItem p,
.statItem span {
    margin: 0;
    font-size: 0.9em;
}

.linkBlue {
    color: var(--primary-color);
    text-decoration: none;
}
  .countAndIcon{
    display: flex;
    align-items: center;
  }