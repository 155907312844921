.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
}

.Page .FooterRow {
  padding-left: 1rem;
}

.LoadingArea {
  padding: 3rem;
}

.ViewAccountsBtn {
  text-transform: capitalize !important;
  color: var(--primary-color);
  background-color: #fff;
  outline: 2px solid var(--primary-color);
}

.ViewAccountsBtn:hover {
  background-color: #fff;
  outline: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.ContentSection {
  padding: 1rem 3rem;
  display: grid;
}

.SummaryCardContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.SummaryCardContainer:hover {
  border: 1px solid var(--secondary-color);
}

.SummaryCardDimensions {
  min-width: 100%;
  display: flex;
}

.CardHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #cccccc;
}

.CardTitle {
  width: 80%;
  font-weight: bold;
}

.CardBodySection {
  max-height: 10rem;
  flex-grow: 1;
  padding: 1rem 0rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.DBSection,
.UserSection {
  min-height: 5rem;
  display: grid;
  grid-template-columns: 30% auto;
}

.LeftDBSide,
.LeftUserSide {
  display: grid;
  grid-template-rows: 2.5rem auto;
  padding-bottom: 1rem;
}

.TopTitle {
  font-weight: bold;
  margin: auto;
  padding: 1rem 0rem 1rem 0rem;
}

/* .DBStats,
.UserStats {
  display: grid;
  grid-template-columns: 49% auto 49%;
  padding-bottom: 1rem;
} */

.MetricsGraph {
  border-left: 1px solid #cccccc;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.UserCount {
  font-size: 32px;
  font-weight: normal;
  padding: 3rem 1rem 3rem 9rem;
}

/* .InnerTitlesStart,
.InnerTitlesMiddle,
.CardTop {
  font-weight: bold;
  text-align: center;
  padding-top: 1rem;
} */

/* .verticalLine {
  border-left: 1px solid #cccccc;
  height: 10rem;
} */

.ResourceCard {
  width: 100%;
  align-items: left;
  background: #fff;
  position: relative;
  color: #000000;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #ccc;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.ResourceDigit {
  flex: 1 1 auto;
  font-size: 2rem;
  font-weight: 500;
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: left;
  color: #008AC1;
  padding-left: 8px;
}

.columnCardSection {
  display: flex;
  flex-direction: column;
}

.rowCardSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
}

.rowResourceDigit {
  flex: 1 1 auto;
  font-size: 1.7rem;
  font-weight: 500;
  box-sizing: border-box;
  padding-top: 0.2rem;
  text-align: left;
  color: #008AC1;
}

.rightTextAlign {
  text-align: right;
  padding-right: 10px;
  padding-left: 0;
}

.ResourceCard .CardHeader {
  color: #000;
  text-align: left;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  padding-left: 12px;
  padding-top: 12px;
  text-transform: lowercase;
  cursor: pointer;
}

.innerCardHeader {
  color: #444;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  cursor: pointer;
}

.innerCardHeader::first-letter {
  text-transform: uppercase;
}

.ResourceCard .CardHeader::first-letter {
  text-transform: uppercase;
}

.ResourceCard:hover {
  opacity: 0.7;
  border: 1px solid var(--secondary-color);
}

.TopRow {
  padding-top: 1rem;
}

.FooterRow {
  padding: 1rem 3rem;
  background-color: var(--gray-bg-light-color);
}

.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.ClusterMetricsCardGraph {
  max-height: 12rem;
}

.AllUsers {
  display: flex;
  margin: auto;
}

.OtherCards {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 4rem;
  overflow-x: hidden;
  padding: 3rem 2rem 0 2rem;
}