.ProjectForm {
  width: 100%;
  font-size: 0.9rem;
}

.CreateProjectError {
  padding-bottom: 1rem;
  align-self: center;
}

.MainContentSection {
  padding-bottom: 1rem;
}

.FormElements {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: auto;
}
.InformationBar {
  background: #f8f7e5;
  height: 5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.InformationBarWithButton {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.InfoHeader {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.Element{
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.Element input {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr;
  grid-gap: 0.2rem;
  width: 100%;
  border-radius: 5px;
}
.Element div div div{
  display: flex;
}
.Element div div input{
  width: 85%;
}

.FormsSection {
  padding: 1rem;
  align-self: center;
  width: 60%;
}
.LeftFormElements {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
}

.TextArea {
  padding: 1rem;
  border: 1px solid black;
  box-sizing: border-box;
  font-size: inherit;
  width: 100%;
}
.TextArea::placeholder {
  color: #808080;
}

.WhiteBackground {
  color: #000;
}
.InitialBackground {
  color: #fff;
  background-color: transparent;
}
.CreateProjectError {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* make it unresizable */
textarea {
  resize: none;
}

.ElementTitle {
  font-weight: bold;
  font-size: 0.9rem;

}
.InnerContent {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.InnerContent button {
  width: 100%;
}




.ClusterCheckboxSection {
  display: flex;
  padding: .4rem 0rem;
  flex-direction: row;
  font-size: 14px;
}

.NormalText{
  font-size: 0.9rem;
}

.ClusterToggleSection{
  display: flex;
  padding: .4rem .7rem;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
  align-items: center;
}
.MultiSelectioOption {
  display: flex;
  padding: .4rem 4.9rem;
  font-size: 14px;
  color: #7c7a7a;
  text-align: center;
  align-items: center;
}
.Multipleclusters{
  display: flex;
  padding: .4rem 4.9rem;
}
li.ListStyle{
  list-style-type: none;
}
.leftsection{
  display: flex;
  align-items: center;
}
.ClusterLabel{
  display: flex;
  padding-left: 2rem;
}
@media(max-width: 650px) {

  .FormsSection {
    width: 90%;
  }

}