/* This Component is ideal for re-use for both the clusters and cluster resources */

.ClusterContainer {
  display: flex;
  gap: 1rem;
  padding: 2rem 1.4rem;
  flex-wrap: wrap;
}

.CardLink {
  text-decoration: none;
}

.ContentSection .ResourceSpinnerWrapper {
  position: relative;
  width: 100%;
  margin-top: 10rem;
}

.ClusterItem {
  min-width: 199px;
  margin: 15px 15px;
  text-align: center;
}

@media only screen and (min-width: 750px) {
  .ClusterContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Bigger than Phones(laptop / desktop) */
@media only screen and (min-width: 970px) {
  .ClusterContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}