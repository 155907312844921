.skeletonLoader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    min-height: 50vh;
    width: 100%;
    margin: 1.5rem;
    flex-grow: 1;
  }
  
  .skeletonLoader .skeleton {
    background-color: #f8f7e5;
    background-image: linear-gradient(90deg, #f8f7e5, #ccc, #f8f7e5);
    background-size: 200% 100%;
    background-position: 200% center;
    animation: loading 1.5s infinite;
    border-radius: 4px;
    width: 100%;
    flex-grow: 1;
  }
  
  .skeletonLoader .skeleton.short {
    height: 20px;
    width: 50%;
  }
  
  .skeletonLoader .skeleton.medium {
    height: 20px;
    width: 75%;
  }
  
  .skeletonLoader .skeleton.long {
    height: 20px;
    width: 100%;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% center;
    }
    100% {
      background-position: -200% center;
    }
  }
  