.OverviewContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.TitleArea {
    padding: 0 2rem;
}

.SummaryCardContainer {
    padding: 2rem;
}

.GraphSummaryTitle {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.SummaryTitleText {
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0.5rem 0 1rem 1rem;
}

.PeriodButton {
    height: 1.7rem;
    width: 3rem;
    background-color: #eeeeee;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.PeriodButtonActive {
    background-color: var(--secondary-color);
}

.ChartsArea {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.piechart{
    display: grid;
    justify-items: center;
}
@media only screen and (min-width: 750px) {
    .ClusterContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Bigger than Phones(laptop / desktop) */
@media only screen and (min-width: 970px) {
    .ClusterContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}