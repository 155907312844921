.tabItem {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.activeTab {
    font-weight: bold;
    background-color: #d9d9d9;
    border-radius: 4px;
    padding: 10px 20px;
}

.inactiveTab,
.activeTab:hover {
    cursor: pointer;
}