.context-menu {
  color: #000;
  background-color: #f9f9f9;
  position: absolute;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.BetaUser {
  display: flex;
  gap: 1rem;
}

.BetaUserText {
  text-align: center;
  font-size: 18px;
}

.creditCoin {
  width: 1rem;
  height: 1rem;
}

.creditSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.4rem;
}

.DropDownLink a {
  color: inherit;
}

.AdminUserPageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.UserProfile {
  display: grid;
  grid-template-columns: 35% auto;
}

.UserAvatar {
  width: 238px;
  height: 15rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 74px;
  display: flex;
  align-items: center;
  /* horizontal */
  justify-content: center;
  /* vertical */
}

.UserProfileInfo {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
}

.ProfileInfoRow {
  display: grid;
  grid-template-columns: 20% auto;
}

.ProfileInfoAttribute {
  display: grid;
  align-items: center;
  color: #008AC1;
  font-weight: bold;
}

.ProfileInfoValue {
  display: grid;
  align-items: center;
  /* max-height: 2rem; */
}

.CreditsButton {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 20% auto;
  height: 1rem;
  align-items: center;
}

.CreditsTable {
  background-color: #ffffff;
  border: 1px solid #ccc;
  height: 100%;
}

.BlueButton {
  background-color: #008AC1;
  color: #ffffff;
  /* padding: 0.5rem 1rem; */
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.CreditsHeader {
  line-height: 2rem;
  padding: .5rem 2rem;
  font-weight: 600;
  font-size: 24px;
  border: 1px solid #ccc;
}

.CreditsHead {
  line-height: 1.8rem;
  padding-left: 2rem;
  font-weight: 500;
  font-size: 20px;
  border: 1px solid #ccc;
  color: #008AC1;
}

.CreditsHeaderRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  border-top: 1px solid #ccc;
  border-top-width: 80%;
}

.Credits {
  height: 100%;
}

.CreditsError {
  display: grid;
  height: 50%;
  place-items: center;
  padding-left: 2rem;
}

.CreditsBody {
  height: 100%;
}

.CreditsWrap {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  width: 3rem;
}

.CreditsRowAttribute {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: .5rem 1rem;
}

.CreditsRowAttribute:hover {
  white-space: normal;
  word-wrap: break-word;
  overflow: visible;
}

.CreditsRowAttribute:first-child {
  padding-left: 2rem;
}

.CreditsRowAttribute:first-child:hover,
.CreditsRowAttribute:last-child:hover {
  overflow: visible;
}

.CreditsRowAttribute:nth-child(3) {
  padding-right: 2rem;
}

.CreditsHeaderAttribute {
  padding: .3rem 0rem;
}

.ManageUserContainer {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.DiasbleContent {
  display: flex;
  flex-direction: center;
  justify-content: space-between;
  padding-top: 10px;
}

.DiasbleUserContent {
  font-weight: bold;
  margin: 5px 0px 0px 10px;
}

.DisableWarning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #999999;
}

.DeleteUserContent {
  font-weight: bold;
  margin: 5px 0px 0px 10px;
  padding: 5px 0px 0px 0px;
}

.DeleteWarning {
  color: red;
  background-color: #ffffff;
}

.ManageUserContainer .DeleteWarning {
  margin-bottom: 10px;
}

.MainPage {
  height: 100%;
}

.ManageUserSection {
  grid-gap: 1rem;
  background: #fff;
  border: 1px solid var(--gray-light-color);
  border-radius: 15px;
  display: grid;
  gap: 1rem;
  padding: 1rem
}

.ManageUserBody {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--gray-light-color);
}

.ManageUserSectionInfo {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.ManagerUserRow {
  display: flex;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  margin: 0;
  background-color: white;
}

.Cluster1Container {
  display: flex;
  padding: 2rem 0.5rem 0 0.5rem;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AdminDBSections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
}

.ProjectInstructions {
  display: grid;
  gap: 1rem;
}

.SettingsSectionInfo {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.3rem;
  font-size: 0.95rem;
  padding: 1rem 0rem 1rem 0rem;
  justify-content: space-between;
  border-bottom: 0.9px solid rgba(204, 204, 204, 0.69);
}



.SubTitleContent {
  font-size: 0.8rem;
}

.SettingsSectionInfo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.3rem;
  font-size: 0.95rem;
  padding: 1rem 0rem 1rem 0rem;
  justify-content: space-between;
}

.AdminUserProfileInfoSect {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.AdminUserProfileInfoHeader {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
}

.AdminUserProfileCard {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1.5rem;
  position: relative;
}

.AdminCardArea {
  padding: 1rem 0;
}

.AdminProfileRowItem:hover {
  color: var(--primary-color);
  cursor: pointer;
  transform: scale(1.05);
}


.AdminProfileRowInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: .9rem;
}

.AdminProfileRowItem {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.6rem;
  font-size: 0.9rem;
}

.AdminProfileRowItem span {
  font-weight: bold;
  white-space: nowrap;
}