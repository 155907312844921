.AppCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 10rem;
  width: 17rem;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.AlertIcon {
  display: flex;
  padding-left: .4rem;
  align-items: center;
}


.AppCardHeader {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AppIconsSection {
  position: relative;
  display: flex;
  align-items: center;
}

.AppIconsSection img {
  height: 1.2rem;
}

.AppCardBottomSection {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}

.AppGraphSummaryLabel {
  padding-top: 2px;
  color: #cccccc;
  font-size: 0.7rem;
}

.AppGraphSummary {
  flex-grow: 1;
}

.AppUrlText {
  flex: 1 1 auto;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: normal;
  box-sizing: border-box;
  margin-top: 1.5rem;
}

.AppUrl {
  flex: 1 1 auto;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  font-size: 14px;
  padding-top: 0px;
}

.AppCard .FailAppCard .AppCardHeader::first-letter {
  text-transform: uppercase;
}

.AppUrl a {
  text-decoration: underline;
  font-size: 0.9rem;
  color: var(--primary-color);
}

.AppCardItem {
  display: flex;
}

.AppName {
  color: #000000;
  cursor: pointer;
  flex: 1;
  display: flex;
}

.AppCategoryIcon {
  width: 25px;
  height: 25px;
}

.AppNameSection:hover {
  opacity: 0.7;
}

.FailAppCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 10rem;
}

.AppCard:hover {
  border: 1px solid var(--secondary-color);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.FailAppCard:hover {
  border: 1px solid #f71f1f;
  box-shadow: 0 1px 2px 0 #f71f1f;
  transition: 0.5s;
}


.AppUrl:hover {
  transition: 0.5s;
  transform: scale(1.02);
}