.ToggleButtonContainer {
    width: 3.7rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .ToggleBase {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    padding: 0 2.2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .ToggleButtonSlider {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var( --gray-bg-light-color);
    border-radius: 50%;
  }
 
  
  .ToggleButtonSlider.ToggleOff {
    right: 2.2px;
  }
  .ToggleButtonSlider.ToggleOn {
    left: 2.2px;
  }
  
  .ToggleBase.ToggleOn {
    background-color: #008AC1;
  }
  
  .ToggleBase.ToggleOff { 
    background-color: var(--secondary-color);
  }
  .ToggleText{
    font-size: 10px;
    padding: .1rem;
    text-align: center;
    justify-content: center;
  }
  