footer.LandingFooter {
  color: #555555;
  text-align: center;
  background-color: var(--gray-bg-light-color);
}

.FooterParent {
  display: flex;
}

.FooterParent .FooterChildOne {
  display: flex;
  flex-direction: column;
}

.FooterParent .FooterChild1 {
  flex: 1;
}

.FooterParent .FooterChild2 {
  flex: 2;
}

.FooterContent {
  display: flex;
  justify-content: space-evenly;
}

.FooterLogoName {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 0.3rem;
  margin-top: 0;
}

.TextDivSpacing {
  text-align: left;
}

.TextDivSpacing a {
  color: #555555;
}

.FooterList {
  padding-left: 0;
}

.FooterList li {
  list-style: none;
  text-align: left;
}

.CenteredDiv {
  padding-left: 2rem;
}

.LogoBrandName {
  text-align: left;
}

li.TextDivSpacing {
  padding: 0.5rem 0;
  color: #555555;
}

li.TextDivSpacing a:visited {
  color: #555555;
}

.SocialsIcons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50%;
  align-items: center;
}

.SocialsIcons a {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .FooterParent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .FooterChild1 {
    padding-bottom: 1rem;
  }

  .SocialsIcons {
    gap: 1rem;
  }
}