.SideBar {
  height: inherit;
  background: var(--primary-dark-color);
  mix-blend-mode: normal;
  margin-left: 0px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}
.SmallSidebar{
  height: inherit;
  background: var(--primary-dark-color);
  mix-blend-mode: normal;
  margin-left: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 50%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: fixed;
}
.SideBarProjectName{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  cursor: pointer;
  font-size: 0.9rem;
}
.SideBarTopSection {
  padding: 2rem 1rem;
  display: flex;
  gap: 0.5rem;
  transition: .3s;
  align-items: center;
  cursor: pointer;
  color: white;
}

.SideBarTopSection:hover{
  transform: scale(1.1);
}


.ProjectName {
  background-color: var(--primary-dark-color);
  font-weight: bold;
  font-size: large;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SideBarBottomSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SideBarLinks {
  flex-grow: 1;
}

.SideBar::-webkit-scrollbar {
  display: none;
}

.ListItem {
  height: 21px;
  background-color: var(--primary-dark-color);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--secondary-color);
  margin-top: 20px;
  cursor: pointer;
  padding-left: 18px;
  text-transform: capitalize;
  display: block;
}


.SubBarListItem {
  height: 21px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-light-color);
  padding: 8px 0px 8px 20px;
  cursor: pointer;
  display: flex;
  gap: .5rem;
  align-items: center;
  text-transform: capitalize;
}

.SubBarListItem > svg {
  width: 1.1rem;
}
.List {
  height: max-content;
  overflow: auto;
}

.ListItem:hover {
  text-shadow: 0 0 0.5px rgb(255, 194, 62);
}

.SubBarListItem:hover,
.active {
  color: var(--primary-dark-color);
  background-color: #7aa6b9;
  padding-left: 20px;
}
.MenuIcon{
  /* height: 3rem;
  width: 3rem;
  position: absolute;
  top: 9rem;
  left: 0.1rem; */
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: .5rem;
  justify-content: center;
  display: flex;
  z-index: 40;
}


.DisabledLink {
  pointer-events: none;
}

.SideFooter {
  height: 30px;
  font-size: 12px;
  line-height: 14px;
  bottom: 0;
  color: var(--primary-color);
  padding: 18px 0 20px 18px;
  cursor: pointer;
  background-color: var(--primary-dark-color);
}
.SideFooter:hover {
  color: rgb(131, 182, 202);
}
