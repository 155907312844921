.BlackInputText {
  width: 100%;
  border: 1px solid #000;
  box-sizing: border-box;
  background-color: transparent;
  color: #000;
  font-size: inherit;
  padding: 0.8rem 1rem;
}

.BlackInputText::placeholder {
  color: #808080;
}
