.FormHeading {
  padding: 0.3rem 0rem;
  font-weight: 600;
}

.FormFieldWithTooltip {
  /* width: 60%; same as input field */
  display: flex;
  position: relative;
}

.FormInputTooltipContainer {
  position: absolute;
  right: 0%;
  padding-right: 1rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
}

.HeadingWithTooltip {
  display: flex;
}

.HeadingWithTooltip h4 {
  margin: 0;
  padding: 0.5rem 0;
}

.Dropzone {
  /* width: 570px; */
  color: #808080;
  padding: 1rem 2rem;
  height: 16rem;
  background-color: whitesmoke;
  border: 3px dashed #aeaeae;
  cursor: pointer;
}

.DropSection {
  padding-bottom: 2rem;
}
.CreateFormHolder {
  min-height: calc(100vh - 14.5rem);
}

.FormInputs {
  background: #ffffff;
  padding: 0.7rem;
  border-radius: 0.7rem;
}

.FormInputs::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.FormInputs::-webkit-scrollbar-track {
  background: var(--gray-bg-light-color);
}

.FormInputs::-webkit-scrollbar-thumb {
  background: #555;
}

.FormInputs::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.FrameworkSelect,
.RegistrySelect,
.FormFieldWithTooltip {
  padding-bottom: 1.5rem;
}

.ButtonSection {
  padding-bottom: 1rem;
}

.MiraLogsFrameContainer {
  border: 1px solid #cccccc;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 15px;
  background-color: #283237;
  color: initial;
  height: 100%;
  border-radius: 15px;
}
.MiraLogsFrameContainer:hover{
  border: 1px solid var(--secondary-color);
}

.logItemTitle{
  color: #88d6fd;
}
.successfulDeployment{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.logsDiv{
  max-height: 27rem;
  overflow: auto;
}

.ErrorDiv {
  background-color: rgba(255, 255, 255, 0.597);
  color: #d63b3b;
  text-align: center;
  padding: 1rem 0;
  border-radius: 5px;
}




