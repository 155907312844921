.ProjectDeleteModel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}

.ProjectDeleteModel .ModalParentWrap {
  background-color: inherit;
}

.ProjectDeleteModel .ModalChildWrap {
  z-index: 3;
}

.DeleteProjectModel {
  display: grid;
  gap: 3rem;
}

.DeleteProjectModalLowerSection {
  display: grid;
  gap: 1rem;
}

.DeleteDescription {
  cursor: default;
  font-weight: 700;
  font-size: 1.1rem;
}

.DeleteDescription span {
  padding: 0.2rem 0.4rem;
  margin: 0;
  font-weight: bold;
  font-size: 90%;
  background-color: rgba(27, 31, 35, 0.1);
  border-radius: 3px;
}

.UpdateProjectModelButtons {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.ModalUpdateForm {
  display: grid;
}

.ModalUpdateForm .ModalFormInputs {
  display: grid;
  gap: 1rem;
}

.ModalUpdateForm .ModalFormInputs .InputText {
  border: 2px solid #000000;
  color: #000;
}

.ModalUpdateForm .ModalFormInputs .InputText::placeholder {
  color: #000;
}

.ModalUpdateForm .ModalFormButtons {
  display: flex;
  justify-content: space-between;
}

.ProjectDeleteModel .ModalChildWrap {
  min-width: 35rem;
}

.UpdateForm {
  display: flex;
  gap: 1rem;
  padding: 1rem 1.3rem;
  flex-direction: column;
}

.UpdateInputSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.ErrorOnWhite {
  width: 25rem;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.Page {
  margin: 0;
  background: var(--gray-bg-light-color);
}

.MainSection {
  /* divided into two columns */
  background-color: var(--gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow: hidden;
}

.TopBarSection {
  background-color: black;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ContentSection {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  /* padding-left: 3rem; */
}

.InnerModalDescription {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.DeleteWarning {
  color: #f00;
}

.InactiveDelete {
  background-color: #fff;
  outline: #ff9595;
  color: #ff9595;
}

.InactiveDelete:hover {
  background-color: #fff;
  outline: #ff9595;
  color: #ff9595;
}

.WarningContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DeleteSubDescription {
  font-weight: 500;
}


.ProjectButtonRow {
  display: grid;
  grid-template-columns: auto 40%;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 1rem;
}

.SettingsSectionInfo {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.SectionButtons {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.ProjectSections {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SettingsSectionInfoHeader {
  font-weight: 600;
  font-size: 1.1rem;
}

.ProjectSectionTitle {
  font-size: 1.3rem;
  font-weight: bold;
}

.DeleteBtn {
  background-color: #ff0000;
  outline: 1px solid #ff0000;
}

.DeleteBtn:hover {
  background-color: #fff;
  outline: 1px solid #ff0000;
  color: #ff0000;
}

@media (max-width: 650px) {
  .MainSection {
    /*one column required */
    background-color: var(--gray-bg-light-color);
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .MainContentSection {
    background-color: var(--gray-bg-light-color);
    flex-direction: column;
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .UpdateInputSection {
    width: 20rem;
  }

  .ContentSection {
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  .TextArea {
    width: 20rem;
    border: 1px solid black;
    box-sizing: border-box;
    font-size: inherit;
    padding: 1rem;
  }
}