.NewResourceCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12.5rem;
    background-color: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    text-align: center;
    transition: transform 0.3s ease;
}

.NewResourceCardHeader {
    font-size: 1.1rem;
}

.NewResourceCardCount {
    font-size: 3rem;
    color: var(--primary-color);
}

.NewResourceCard .NewResourceCardHeader::first-letter {
    text-transform: uppercase;
}

.NewResourceCard:hover {
    cursor: pointer;
    transform: scale(1.05);
}