.tagInputContainer {
    /* padding: 10px; */
    display: inline-block;
    width: 100%;
    min-height: 5vh;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 400px;
    margin-bottom: 20px;
}

.tag {
    background-color: #e0f7ff;
    border-radius: 15px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color:  var(--primary-color);
}

.tag:hover {
    background-color: #c0e7ff;
    color:  var(--primary-color);
}

.tag button {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
    color:  var(--primary-color);
}

.tag button:hover {
    color:  var(--primary-color);
}

.input {
    border: 1px solid  var(--primary-color);
    outline: none;
    width: calc(100% - 20px);
    margin-top: 5px;
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
    border-radius: 5px;
}



.suggestions {
    border-radius: 5px;
    margin-top: 5px;
    padding-left: 4px;
    position: absolute;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 60%;
    min-height: 3rem;
    justify-content: flex-start;
    max-width: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.suggestion {
    padding: 10px 4px;
    width: 100%;
    cursor: pointer;
    color: #333;
}

.suggestion:hover {
    background-color: #e0f7ff;
}

.addNewTag {
    padding: 10px;
    cursor: pointer;
    background-color: #e0f7ff;
    border-top: 1px solid #ccc;
    width: 5rem;
    width: 100%;
    color:  var(--primary-color);
}

.addNewTag:hover {
    background-color: #c0e7ff;
    color:  var(--primary-color);
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 0.9rem;
}
