.projectCards {
    position: relative;
    background-color: #f8f9fa;
    padding: 0.2rem 1rem 1rem 1rem;
    border-radius: 8px;
    margin: 10px;
}
.projectCards .viewMoreButton{
  position: absolute;
  top: 25px;
  right: 10px;
  margin-right:0;
  padding: 2px;
}
/* .viewMoreButton {
    width: 100%;
} */

.noActivity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
}