.OverviewContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.TitleArea {
  padding: 0 0.5rem;
}

.APage {
  margin: 0;
  background: var(--gray-bg-light-color);
}

.AMainSection .ClusterContainer {
  padding: 2rem;
}

.AChartsArea {
  width: 100%;
}


.TitleArea .XSearchBar {
  padding-bottom: 0.5rem;
}

.TitleArea .XSearchBar .AdminSearchInput input {
  padding: 1.5rem;
}

.TitleArea .XSearchBar .AdminSearchInput .SearchIcon {
  height: 49px;
}

.VisualArea .SectionTitle {
  font-weight: bold;
  border-bottom: none;
}

.AppChartContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 2rem;
  gap: 20px;
}
.appTotal {
  background-color: #6cc04a;
  /* width: 100%; */
}

.appRunning{
  background-color: #f7b21f;
}

.appDown{
  background-color: #ff0000;
}

.adminAppTotal {
  background-color: #6cc04a;
  width: 100%;
}

.adminAppUpTotal {
  background-color: #f7b21f;
  width: 90%;
}
.adminAppDownTotal {
  background-color: #ff0000;
  width: 10%;
}

.progress {
  height: 25px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: initial;
}
.progress-bar {
  float: left;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  text-align: center;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.VisualArea {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.VisualAreaHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.VisualAreaText {
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.PieContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ChartColumn {
  flex: 1;
  padding-right: 20px;
  /* Add spacing between the columns */
}

.PercentageColumn {
  padding-right: 3rem;
}

.AppMetricItems {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media only screen and (max-width: 900px) {
  .ChartContainer {
    display: flex;
    flex-direction: column;
  }
}

.AppSectionTitle {
  background-color: #ebead5;
}

.sr-only {
  display: flex;
  padding: 0 5px;
  justify-content: flex-end;
  align-items: center;
  height: inherit;
}

.AppDivision {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.cube {
  width: 1.8rem;
  height: 1.8rem;
}
.cubeList {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.cubeContainer {
  display: grid;
  gap: 1rem;
}

/* .AdminappsContainer{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2rem;
} */