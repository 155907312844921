.InputText {
  border: 1px solid #fff;
  box-sizing: border-box;
  font-size: inherit;
  padding: 0.8rem;
  border-radius: 5px;
}
.InputText::placeholder {
  color: #fff;
}

.WhiteBackground {
  color: #000;
}
.InitialBackground {
  color: #fff;
  background-color: transparent;
}
