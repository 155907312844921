.TimeInputContainer {
  font-weight: bold;
  text-transform: capitalize;
}

.TimeInputWrapper {
  border: 2px solid var(--secondary-color);
  width: 5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 2px;
  border-radius: 0.3rem;
}

.TimeInputWrapper:active {
  cursor: pointer;
  background-color: black;
}

.TimeInput {
  width: 1.2rem;
  text-align: center;
  border: none;
}

/* Remove the arrows on input element */
/* Chrome, Safari, Edge, Opera */
.TimeInput::-webkit-outer-spin-button,
.TimeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.TimeInput[type="number"] {
  -moz-appearance: textfield;
}

.TimeInput::selection {
  background-color: #cccccc;
}

.DayNightInput {
  text-transform: uppercase;
  width: 1.5rem;
}
