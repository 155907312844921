.dashboard {
    display: flex;
    flex-wrap: wrap;
}

.projectSection {
    flex: 1;
    min-width: 200px;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}

.activitySection {
    flex: 2;
    min-width: 300px;
    overflow-y: auto;
}

.rightSection {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
}

.sticky {
    position: sticky;
    top: 0;
}
.searchListSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    height: fit-content;
    width: 100%;
    margin: 1.5rem;
    flex-grow: 1;
}


  .errorMessageSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    min-height: 40vh;
    width: 100%;
    margin: 1.5rem;
  }
  .errorText{
    color: red;
  }
  
  .resourceContainer {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
  
  .resourceLabel {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    color: var(--primary-color);
  }
  
  .resourceRaw {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .resource{
    width: 20rem;
  }
  
  

@media (max-width: 768px) {
    .projectSection {
        position: relative;
        height: auto;
    }

    .activitySection,
    .rightSection {
        overflow-y: visible;
    }
}