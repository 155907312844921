.Page {
  margin: 0;
  background: var( --gray-bg-light-color);
  max-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* overflow: hidden; */
}

.TopBarSection {
  background-color: black;
}

.MainSection {
  /* divided into two columns */
  background-color: var( --gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow: auto;
}

.SmallContainer {
  padding: 0 2rem;
}

@media (max-width: 580px) {
  .SmallContainer {
    padding: 0 1rem 1rem 2rem;
  }
}

.field {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  width: -webkit-fill-available;
  border-radius: unset;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.Heading {
  font-weight: bolder;
  line-height: 1.8;
  font-size: larger;
}

.SimpleForm {
  display: flex;
  width: 60%;
  gap: 0.5rem;
}

.SmallContainer {
  padding: 2rem;
  background: var( --gray-bg-light-color);
}

.field {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  width: -webkit-fill-available;
  border-radius: unset;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Heading {
  font-weight: bolder;
  line-height: 1.8;
  font-size: larger;
}

.SimpleForm {
  display: flex;
  width: 60%;
  gap: .5rem;
}

.BackButton {
  background: var(--primary-color);
  outline: none;
}

.FilterButton {
  outline: none;
  background: unset;
  border: 1px solid black;
  color: #000;
}

.FilterButton:hover {
  border: none;
}

.Table {
  background: #ffffff;
  border-radius: 15px;
  padding: 1rem 2rem;
}

.TableRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.Success {
  color: green;
}

.Danger {
  color: red;
}

.Entity {
  color: var(--primary-color);
}

.hr {
  margin: .5rem 0;
}

.Row {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.Bold {
  font-weight: bold;
  font-size: 17px;
}

.RowCell {
  display: flex;
  gap: 1rem;
}

.LastCell {
  align-self: flex-end;
}

.UserAvatar {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}