
  .SectionTitle {
    font-size: 1.8rem;
    font-weight: 700;
    color: #333333;
    margin-bottom: 1rem;
  }
  
  .AdminCardArea {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .AdminUserProfileCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .AdminUserProfileInfoSect {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  
  .ChartsContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .Chart {
    position: relative;
  }
  
  .ChartLabel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 9px;
    font-weight: 700;
    color: #333333;
    padding: auto;
    margin-top: 30px;
    text-align: center;
  }
  
  .Identity {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }
  
  .IdentityName {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333333;
    text-transform: capitalize;
  }
  
  .BetaUserDiv {
    padding: 0.3rem 0.5rem;
    border-radius: 999px;
    background-color: rgba(128, 0, 128, 0.217);
    color: purple;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: normal;
    margin-left: 0.5rem;
  }
  
  .IdentityEmail {
    color: #666666;
    font-size: 0.95rem;
  }
  
  .Organization {
    font-size: 0.95rem;
    color: #4d4d4d;
  }
  
  .DateStyles {
    font-size: 0.85rem;
    color: #888888;
    text-align: center;
  }
  
  .dateStyle {
    font-weight: 600;
  }
  
  .FollowButton {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .FollowButton:hover {
    background-color: #0073e6;
    color: #ffffff;
  }
  
  .AdminProfileRowInfo {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    text-align: left;
    font-size: 12px;
  }
  
  .AdminProfileRowItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .SingleChart{
     display: flex;
     flex-direction: column;
     align-items: center;
    }
  
  @media only screen and (max-width: 700px) {
    .ProfileAnalytics {
      padding: 1rem;
    }
  
    .ChartsContainer {
      flex-direction: column;
      gap: 1rem;
    }
  
    .IdentityName {
      font-size: 1.3rem;
    }
  
    .DateStyles {
      display: none;
    }
  }
  