.LineChartContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChartLabel {
  font-weight: 500;
  text-anchor: middle;
}

.GraphSection {
  display: flex;
  font-family: monaco;
  font-size: 0.7rem;
  color: var(--gray-color);
}

.NoGraphData {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
