.CancelButton {
  width: 56px;
  height: 56px;
  background-color: #ff9595;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CancelButton:hover {
  box-shadow: none;
  border-color: transparent;
  outline: none;
}
