.InformationBar {
  background: #f8f7e5;
  /* min-height: 4rem; */
  display: flex;
  align-items: center;
}

.InformationBarWithButton {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.InfoHeader {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.3rem;
  gap: 0.5rem;
}

.InfoContent {
  display: flex;
  justify-content: flex-end;
  gap: 4rem;
  align-items: center;
}

.CreditsCorner {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  color: var(--secondary-color);
  grid-gap: .5rem;
  width: 3rem;
}


.SearchInput {
  display: flex;
  align-items: center;
  background-color: #EBEAD5;
  border-radius: 5px;
  width: 100%;
}

.SearchInput input {
  background-color: transparent;
  color: #000;
  border: none;
  padding: 0.7rem 1.1rem;
  height: 100%;
  /* max-width: 21rem; */
}

.InformationBarColumnView {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0.5rem 0;
  width: 100%;
}

.SearchIcon {
  background-color: transparent;
  height: 100%;
  padding: 0 0.4rem;
  width: 1.3rem;
}

.ButtonWrap {
  display: flex;
  gap: 1rem;
}

.InfoProjectCategories {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-weight: normal;
  font-size: 1.1rem;
  width: 11rem;
}
.MobileInfoProjectCategories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MobileInfoProjectCategories button {
  outline: none;
  border: none;
  gap: 1.5rem;
  font-size: 14px;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  background-color: #d9d9d9; 
}

.InfoProjectCategories:hover {
  cursor: pointer;
}


.InfoProjectCategories button {
  outline: none;
  border: none;
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  background-color: #d9d9d9;
}

.InfoProjectCategories svg,
.MobileInfoProjectCategories svg {
  height: 1rem;
  width: 1rem;
}

.InfoCurrentTab {
  background-color: #d9d9d9;
  padding: 0.5rem;
  border-radius: 4px;
  font-weight: bold;
}

.InfoTab {
  padding: 0.5rem;
  border-radius: 4px;
}

.InfoBackBtn {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: .5rem;
  transition: ease-in;
}

.InfoBackBtn:hover {
  transform: scale(1.2);
}

.InfoHeader .SelectWrapper {
  padding-bottom: 0.9rem;
  max-height: 20px;
}

.selectOptions {
  cursor: pointer;
}

@media (max-width: 650px) {

  .SearchInput input {
    width: 100%;
  }
  .InfoHeader {
    font-size: 15px;
  }
  .InfoProjectCategories{
    width: 100%;
  }
}