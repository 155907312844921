.DatabaseForm {
  max-width: 20rem;
  padding-top: 2rem;
  height: 100%;
  padding-bottom: 1rem;
}

.CreateDBError{
  padding-bottom: 1rem;
}

.SelectWrapper {
  width: 100%;
}


.DBFormElements {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.CreatePage{
  width: 100%;
  margin: 0;
  background: var( --gray-bg-light-color);
}

@media (max-width: 650px) {
.DatabaseForm{
padding-top: 5rem;
}
}
