.ModalParentWrap {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgb(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}

.ModalChildWrap {
  position: absolute;
  width: auto;
  min-height: 100px;
  height: auto;
  max-height: 80%;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.ModalForm {
  display: grid;
  gap: 2rem;
}

.ModalFormHeading h2 {
  margin: 0;
  padding: 0 0 1rem 0;
  font-weight: bold;
}

.ModalForm .ModalFormInputs {
  display: grid;
  gap: 1rem;
}

.ModalForm .ModalFormInputs .InputText {
  border: 2px solid #000000;
  color: #000;
}

.ModalForm .ModalFormInputs .InputText::placeholder {
  color: #000;
}

.ModalForm .ModalFormButtons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  border-radius: 1.5rem;
}

.ModalChildWrap::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.ModalChildWrap::-webkit-scrollbar-track {
  background: var(--gray-bg-light-color);
}

.ModalChildWrap::-webkit-scrollbar-thumb {
  background: #555;
}

.ModalChildWrap::-webkit-scrollbar-thumb:hover {
  background: #000;
}