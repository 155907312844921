.DateInputContainer {
  display: inline-block;
  width: 100%;
  z-index: 50;
}

.DateInputWrapper {
  display: flex;
  cursor: default;
}

.DateInputLabel {
  user-select: none;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;
  border-radius: 0.3rem;
}

.DateInputDisplay {
  border: 2px solid #cccccc;
  height: 1rem;
  padding: 0.2rem .4rem;
  color: #555555;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DisplayActive {
  border: 2px solid var(--secondary-color);
}

.DateInputCalendar {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 0.5rem;
  display: grid;
  gap: 1rem;
  position: absolute; /*relative */
  top: 3rem;
  z-index: 1;
  left: 1px;
  width: 20rem;
  border-radius: 10px;
  /* margin-top: 0.8rem; */
}
.DateInputsSection>.DateInputCalendar {
  padding-right: 2rem;
}

.CalendarModalButtons {
  display: flex;
  justify-content: space-between;
}

.ModalBtn {
  max-height: 2rem;
}
