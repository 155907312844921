.Page {
  margin: 0;
  background: var( --gray-bg-light-color);
  max-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* overflow: hidden; */
}

.NoResourcesMesssage {
  flex-grow: 1;
  display: flex;
  font-size: 0.9rem;
  padding: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.TopBarSection {
  background-color: black;
}

.MainSection {
  /* divided into two columns */
  background-color: var( --gray-bg-light-color);
  display: grid;
  grid-template-columns: 1.3fr 6fr;
  overflow: hidden;
}

.SideBarSection {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 5rem);
}

.SideBarSection::-webkit-scrollbar {
  display: none;
  min-width: fit-content;
}

.SideBarSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MainContentSection {
  /* divided into two rows */
  display: grid;
  grid-template-rows: 80px 4fr;
  overflow: auto;
}

.SmallContainer {
  padding: 0 2rem 2rem 2rem;
}

@media (max-width: 580px) {
  .SmallContainer {
    padding: 0 1rem 1rem 2rem;
  }
}

.OuterContainerBorder {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.DonutChatContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5;
  max-width: 25rem;
  border-radius: 15px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}

.InsideHeading {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #cccccc;
}

.Heading,
.TransactionHistoryHeading {
  padding: 0.3rem;
  font-size: large;
  font-weight: bold;
}

.Subtext2 {
  font-weight: 800;
}

.MonthSummary {
  display: flex;
  flex-direction: column;
}

.InnerContainer {
  padding: 0 1rem;
}

.ResourseDetail {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 0.5rem;
  border-bottom: 1px solid #000000;
  padding: 0.2rem;
}

.Cube {
  display: flex;
  width: 1rem;
  height: 1rem;
}

.ResourcePrice {
  font-weight: 700;
  text-align: end;
}

.Total {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0.8rem 0.3rem;
}

.TotalTxt {
  text-align: start;
}

.paymentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1rem 0.5rem 1rem;
}

.CancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BarGraphContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 15px;
  border: 1px solid #cccccc;
  width: 100%;
  background-color: #ffffff;
}

.MetricsCardGraph {
  width: 90%;
  height: 25rem;
  align-self: flex-start;
}

.MetricContainer {
  display: flex;
  padding-bottom: 1rem;
  justify-items: center;
}

.PaymentModalSubHeader {
  font-size: 24px;
  font-weight: 500;
}

.TransactionHistoryWrapper {
  padding-top: 1.5rem;
}

.TransactionHistoryContainer {
  border: 1px solid #cccccc;
  background-color: white;
  border-radius: 15px;
}

.TransactionHistoryHeading {
  padding: 1rem 0 1rem 2rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  border-bottom: 1px solid #cccccc;
}

.UsageHistoryHeading {
  padding: 0.5rem 0 1rem 0rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.CurrentTab {
  background-color: #d9d9d9;
  padding: 0.5rem;
  border-radius: 4px;
}

.CurrentTab:hover,
.Tab:hover {
  cursor: pointer;
}

.Tab {
  padding: 0.5rem;
}

.TransactionHistoryBody,
.InvoiceHistoryBody,
.ReceiptHistoryBody {
  padding: 2rem;
}

.ModalHistoryBody {
  padding: 2rem;
}

.TransactionHistoryRow,
.TransactionHistoryHead,
.InvoiceHistoryHead,
.InvoiceHistoryRow {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 10px;
  border-bottom: 1px solid #ccc;
  padding: 1rem;
}
.CBLabel {
  display: grid;
  grid-template-columns: 3% 10%;
  gap: 0.4rem;
}

.InvoiceModalHistoryHead,
.InvoiceModalHistoryRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
  border-bottom: 1px solid #ccc;
  padding: 3rem;
}

.TransactionHistoryCell,
.InvoiceHistoryCell,
.ReceiptHistoryCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TransactionHistoryCell:hover,
.InvoiceHistoryCell:hover,
.ReceiptHistoryCell:hover {
  overflow: visible;
  white-space: normal;
  text-overflow: initial;
  overflow-wrap: anywhere;
}

.TransactionHistoryHead .TransactionHistoryCell,
.InvoiceHistoryHead,
.ReceiptHistoryHead {
  text-transform: uppercase;
  color: var(--primary-color);
}

.ReceiptHistoryHead,
.ReceiptHistoryRow {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.5rem;
  border-bottom: 1px solid #ccc;
  padding: 1rem;
}

.TransactionHistoryCell .PaymentStatus {
  padding: 0.3rem;
  border-radius: 0.3rem;
  background-color: #408140;
  color: #ffffff;
}

.TransactionHistoryRow .TransactionHistoryCell:first-child,
.TransactionHistoryHead .TransactionHistoryCell:first-child,
.InvoiceHistoryRow .InvoiceHistoryCell:first-child,
.InvoiceHistoryHead .InvoiceHistoryCell:first-child {
  text-align: left;
}

.TransactionHistoryRow .TransactionHistoryCell:last-child,
.TransactionHistoryHead .TransactionHistoryCell:last-child,
.InvoiceHistoryRow .InvoiceHistoryCell:last-child,
.InvoiceHistoryHead .InvoiceHistoryCell:last-child {
  text-align: right;
}

.PaymentDetailsButton,
.InvoiceDownloadButton,
.ReceiptDownloadButton {
  color: var(--primary-color);
  outline: none;
  border: none;
  cursor: pointer;
  background: transparent;
}

.PaymentDetailsButton:hover,
.InvoiceDownloadButton:hover,
.ReceiptDownloadButton:hover {
  text-decoration: underline;
}

.ReceiptModal .TotalTxt {
  font-weight: 700;
}

.ReceiptDetailContainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
}

.ReceiptLabel {
  font-size: 0.8rem;
  color: #9b9b9b;
}

.ReceiptDetail {
  font-weight: 700;
}

.ReceiptDetail .PaymentStatus {
  font-weight: normal;
  padding: 0.3rem;
  border-radius: 0.3rem;
  max-width: 20%;
  background: #408140;
  color: #ffffff;
}

.ReceiptModal .ResourseDetail,
.ReceiptModal .Total {
  padding: 0.8rem;
}
.OptionsModal {
  padding: 0.5rem;
}
.MainModalTitle {
  font-weight: 900;
  font-size: 2rem;
}
.SubModalTitle {
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  padding-bottom: 0.5rem;
}
.Iconsection {
  display: flex;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  flex-direction: column;
  padding-top: 0.5rem;
  align-self: center;
}
.PaymentForms {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 1rem;
}
.PaymentFormBox {
  display: flex;
  flex-direction: column;
  width: 7rem;
  height: 6rem;
  background-color: #f8f7e5;
  align-items: center;
  gap: 0.3rem;
}
.SelectedPaymentFormBox {
  display: flex;
  flex-direction: column;
  width: 7rem;
  height: 6rem;
  background-color: #f8f7e5;
  align-items: center;
  gap: 0.3rem;
  border: 1px solid #000000;
}
.PaymentText {
  color: black;
  text-align: center;
  font-weight: 700;
  padding: 0.3rem 0;
}
.CoinSize {
  display: flex;
  width: 3rem;
}
.FlutterWaveSize {
  display: flex;
  width: 6rem;
}
.PaymentFormBox:hover {
  border: 1px solid #000000;
}

.ReceiptButton {
  display: flex;
  justify-content: center;
}
.OptionButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.ModalHistoryHeading {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.InvoiceModalTitle,
.ReceiptModalTitle {
  font-size: large;
  font-weight: bold;
}

.InvoiceID {
  font-weight: normal;
}

.ContactDetails {
  display: grid;
  gap: 1rem;
}

.ViewFileLowerSection {
  padding: 1rem 2rem 0 2rem;
}

.ViewFileModalButtons {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .OuterContainerBorder {
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: fit-content;
  }

  .DonutChatContainer {
    max-width: 100%;
  }
}
