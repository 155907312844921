.AppList {
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  flex-wrap: wrap;
  min-height: 40vh;
}

.NoAppsResourcesMessage {
  flex-grow: 1;
  display: flex;
  font-size: 0.9rem;
  padding-top: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.Spinnerheight {
  min-height: 40vh;
}

.NoAppsError {
  text-align: center;
  justify-content: center;
  padding-top: 3rem;
}

.ButtonPlusSmall {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.PaginationSection {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1100px) and (min-width: 901px) {
  .AppList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 900px) and (min-width: 481px) {
  .AppList {
    padding-top: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .AppList {
    padding-top: 3rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
