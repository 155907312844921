.Logo {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
}

.LogoWrapper ,
.homeLogoWrapper
{
  display: flex;
}

.LogoIcon {
  width: 4rem;
  height: auto;
}

.LogoIcon .solid {
  fill: #fff;
}

.BrandLogoType {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.5rem;
}

@media (max-width: 650px) {
  .BrandLogoType {
    font-weight: 600;
    font-size: 0.9rem;
  } 
  .LogoIcon {
    width: 1rem;
    height: auto;
  }
  .BrandLogoType{
    font-size: 16px;
  }
}


