.MetricsCardGraph {
  height: 25rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PeriodContainer {
  display: flex;
  align-items: center;
}

.ErrorMessage {
  color: red; 
  font-size: 0.9rem;
  margin-right: 15px;
}
.SelectedDates{
 color: var(--primary-color);
 
}

.SelectedContainer{
 display: flex;
 align-items: center;
}

@media (min-width:1366px) {
  .MetricsCardGraph {
    height: 32rem;
    margin-top: 1rem;
  }
}

.ContentSectionSpinner {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
