.SpendPeriodContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .SpendPeriodButtonsSection {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 0.5rem;
    padding: 10px;
    position: relative;
  }
  
  .PeriodButton {
    height: 1.7rem;
    width: 3rem;
    background-color: #eeeeee;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .PeriodButtonActive {
    background-color: var(--secondary-color);
  }
  
  .SpendDateInputsSection {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-self: center;
    justify-content: space-between;
  }
  
  .PeriodButtonCustom {
    width: 5rem;
  }
  .SpendButton{
      justify-self: center;
      height: 1.5rem;
      width: 80%;
  }
  
  .SpendCalendarModal {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    display: grid;
    gap: 1rem;
    padding: .5rem .5rem;
    top: 3rem;
    right: 3rem;
    z-index: 3;
    width: 17rem;
  }
  
.SpendSelectDate{
   display: grid;
   grid-template-rows: 1fr 1fr;
   padding: .1rem;
}
.Calenderposition{
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 0.5rem;
  display: grid;
  gap: 1rem;
  position: absolute; /*relative */
  top: 5.7rem;
  left: 0rem;
  width: 17rem;
}
.SpendDateInputSection{
 display: grid;
 grid-template-columns: 1fr 3fr;
 gap: .4rem;
 padding: .2rem 0rem;
 align-items: center;
 justify-self: center;
}