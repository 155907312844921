.CalendarWrapper {
  user-select: none;
  border: 1px solid #cccccc;
  border-bottom: none;
  background-color: #ffffff;
}

.CalendarMonth {
  padding: 1rem 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #f8f7e5;
}

.CalendarDayNames {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  color: #555555;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
}

.WeekDay {
  padding: 0.5rem 0 0.2rem;
  text-align: center;
}

.CalendarWeekDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid #cccccc;
}

.CalendarSingleDay {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-left: 1px solid #cccccc;
}

.CalendarSingleDay:first-child {
  border-left: none;
}

.CalendarArrow {
  cursor: pointer;
}

.CalendarSingleDay:hover {
  background-color: #f8f7e5;
}

.CalendarSingleDay.Today {
  background-color: var(--secondary-color);
}