.Logo {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
}

.LogoWrapper ,
.homeLogoWrapper
{
  display: flex;
}

.LogoIcon {
  width: 4rem;
  height: auto;
}

.LogoIcon .solid {
  fill: #fff;
}

.LogoType {
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}

@media (max-width: 650px) {

  .LogoType{
    font-size: 16px;
  }
  .Logo {
    grid-gap: .6rem;
  }
  .LogoWrapper {
    position: relative;
    left: 30%;
  }
}