.TooltipContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
}

.TooltipPlaceholder {
  color: var(--secondary-color);
  position: relative;
  overflow-y: unset;
  z-index: 2;
  cursor: pointer;
}

.TooltipWordPlaceholder {
  font-weight: bold;
}

/* Hide the tooltip content by default */
[tooltip-message]:before,
[tooltip-message]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[tooltip-message]:before {
  position: absolute;
  bottom: 150%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 200px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(tooltip-message);
  text-align: left;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 0.3rem;
}

/* Triangle hack to make tooltip look like a speech bubble */
[tooltip-message]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[tooltip-message]:hover:before,
[tooltip-message]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Positioning the tooltip */
[position="top"]:before {
  left: 50%;
}

[position="left"]:before {
  right: 0;
}

[position="right"]:before {
  left: 50%;
}