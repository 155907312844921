.DetailContainer {
  display: flex;
  padding: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.DetailFirst,
.DetailSecond {
  background-color: #ffffff;
  border-radius: 5px;
}

.DetailFirstTitle {
  background-color: #ddd;
  padding: 1rem 2rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.3;
}

.DetailFirst {
  width: 55%;
  max-height: fit-content;
}

.DetailSecond {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DetailSecondColumn {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  width: 40%;
}

.RevisionCard,
.SecondaryCard {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 1rem;
}

.Revision {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.5rem 1rem;
}

.listItem {
  position: relative;
  display: block;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.DetailFirstDetail {
  margin: 2rem;
}

.listItem strong {
  color: var(--primary-color);
}

.SpinnerArea {
  padding: 2rem 0;
  height: 5vh;
}